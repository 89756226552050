import React, { useEffect } from 'react';
// @ts-ignore
import queryString from 'query-string';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import BackArrow from '../../components/BackArrow';
import { PROFILE_FIELD } from '../../components/ProfileCard';
import { Container, ProfileEditContainer } from './style';
import SubscriptionInfo from '../../components/Subscription';
import PersonalInfo from '../../components/PersonalInfos';
import AccountData from '../../components/AccountData';
import Integration from '../../components/Integration';
import { integrateGarmin, integrateStrava } from '../../store/Auth.store';
import { useQuery } from '../../utils';
import MyShoes from '../../components/MyShoes';

const useGarminIntegration = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parsedParams = queryString.parse(location.search);
  const { oauth_token, oauth_token_secret, oauth_verifier } = parsedParams;
  const query = useQuery();

  useEffect(() => {
    const isGarminIntegrationRedirect = oauth_token != null && oauth_token_secret != null && oauth_verifier != null;

    if (isGarminIntegrationRedirect) {
      query.set('field', PROFILE_FIELD.INTEGRACOES);

      dispatch(integrateGarmin(window.location.href) as any);
    }
  }, [oauth_token, oauth_token_secret, oauth_verifier]);
};

const useStravaIntegration = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const parsedParams = queryString.parse(location.search);
  const { code } = parsedParams;
  const query = useQuery();

  useEffect(() => {
    const isStravaIntegrationRedirect = code != null;

    if (isStravaIntegrationRedirect) {
      query.set('field', PROFILE_FIELD.INTEGRACOES);

      dispatch(integrateStrava(window.location.href) as any);
    }
  }, [code]);
};

export default function ProfileEdit() {
  useGarminIntegration();
  useStravaIntegration();

  const query = useQuery();
  const field = query.get('field');

  return (
    <Container>
      <BackArrow text="PERFIL E CONFIGURAÇÕES" bigger backTo="/profile" />
      <ProfileEditContainer>
        {field === PROFILE_FIELD.DADOS_DA_CONTA ? (
          <AccountData />
        ) : field === PROFILE_FIELD.FINANCEIRO ? (
          <SubscriptionInfo />
        ) : field === PROFILE_FIELD.INFORMACOES_PESSOAIS ? (
          <PersonalInfo />
        ) : field === PROFILE_FIELD.INTEGRACOES ? (
          <Integration />
        ) : field === PROFILE_FIELD.MEUS_TENIS ? (
          <MyShoes />
        ) : <div>espaço para futuras notificações</div>}
      </ProfileEditContainer>
    </Container>
  );
}
