import React, { useEffect, useState } from 'react';
import { useGoogleLogin } from '@react-oauth/google';
import { MdOutlineEmail, MdLockOutline } from 'react-icons/md';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import AppleLogin from 'react-apple-login';
import toast from 'react-hot-toast';
import axios from 'axios';
import {
  Card, Container, ForgotPasswordLink, Form, Input, InputGroup, Logo, SignInButton, SignUpLink, Title,
} from './styles';
import logo from '../../assets/logo.png';
import theme from '../../theme';
import { login, loginWithGoogle } from '../../store/Auth.store';
import { RootState } from '../../store';
import Loader from '../../components/Loader';
import SocialLoginButton from '../../components/SocialLoginButton';
import { LOGIN_API_URL } from '../../services/api';

interface IErrorCallback {
  hasError: boolean;
  title: string;
  message: string;
}

export default function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const loading = useSelector((state: RootState) => state.auth.loading);

  const token = useSelector((state: RootState) => state.auth.token);
  const path = useSelector((state: RootState) => state.auth.path);

  const dispatch = useDispatch();

  const navigate = useNavigate();

  function errorCallback({ hasError, title, message }: IErrorCallback) {
    if (hasError) {
      toast.error(`${title}: ${message}`);
    }
  }

  function handleLogin(e: any) {
    e.preventDefault();

    dispatch(login(email, password, errorCallback) as any);
  }

  const googleLogin = useGoogleLogin({
    onSuccess: async (tokenResponse) => {
      const userInfo = await axios
        .get('https://www.googleapis.com/oauth2/v3/userinfo', {
          headers: { Authorization: `Bearer ${tokenResponse.access_token}` },
        })
        .then((res) => res.data);

      const data = {
        name: userInfo.name ?? `${userInfo.givenName} ${userInfo.familyName}`,
        email: userInfo.email,
        token: tokenResponse.access_token,
        photo: userInfo.picture ?? '',
        provider: 'google',
        origin: 'WEB',
      };

      dispatch(loginWithGoogle(data, errorCallback) as any);
    },
  });

  useEffect(() => {
    if (token) {
      if (path) {
        navigate(path);
      } else {
        navigate('/home');
      }
    }
  }, [token]);

  return (
    <Container>
      <Card>
        <Logo src={logo} alt="logo" />

        <Title>ÁREA PARA MEMBROS DO CLUBE</Title>
        <Title>CORRIDA PERFEITA</Title>

        <Form onSubmit={handleLogin}>
          <InputGroup>
            <MdOutlineEmail size={20} color={theme.colors.black} />
            <Input
              type="email"
              placeholder="Digite seu e-mail"
              name="email"
              required
              autoFocus
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </InputGroup>

          <br />

          <InputGroup>
            <MdLockOutline size={20} color={theme.colors.black} />
            <Input
              type="password"
              placeholder="Sua senha secreta"
              name="password"
              required
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </InputGroup>

          <SignInButton type="submit">
            {
              loading ? <Loader /> : 'Acessar'
            }
          </SignInButton>
        </Form>

        <SocialLoginButton
          title="Fazer login com Google"
          loading={false}
          type="google"
          onClick={() => googleLogin()}
        />
        <AppleLogin
          clientId="com.corridaperfeita.web"
          redirectURI={`${LOGIN_API_URL}/apple`}
          callback={() => { }}
          responseType="code id_token"
          responseMode="form_post"
          scope="name email"
          render={(renderProps) => (
            <SocialLoginButton
              title="Fazer login com Apple"
              loading={false}
              type="apple"
              onClick={renderProps.onClick}
            />
          )}
        />

        <ForgotPasswordLink to="/solicitar-recuperar-senha">Recuperar senha</ForgotPasswordLink>

        <SignUpLink onClick={(e) => {
          e.preventDefault(); window.location.href = 'https://corridaperfeita.com/clube/';
        }}
        >
          Ainda não faz parte?

        </SignUpLink>
      </Card>
    </Container>
  );
}
