import React from 'react';
import { User } from '../../../store/Auth.store';
import {
  SetStartAtScreen,
  SetDaysWeeklyWorkoutScreen,
  SetMeasurementScreen,
  SetNotesScreen,
  SetSelectedWeekScreen,
  SetDistanceHistoryScreen,
} from '../CommonScreens';
import { ITrainingRequestScreen } from '../interface';

export interface IReturnInitiationProps extends ITrainingRequestScreen {
  setDistanceHistory: (value: any) => void,
  distanceHistory: any,
  daysWeeklyWorkout: string | null,
  setHeight: (value: string) => void,
  height: string,
  setWeight: (value: string) => void,
  weight: string | null,
  profile: User,
  setDaysWeeklyWorkout: (value: string | null) => void,
  selectedWeek: number | null,
  handleSetSelectedWeek: (value: number) => void
}

export default function ReturnInitiation({
  selectedSchema,
  step,
  setValueForEnableNextButton,
  setDaysWeeklyWorkout,
  daysWeeklyWorkout,
  selectedWeek,
  handleSetSelectedWeek,
  minStartAt,
  startAt,
  handleStartAt,
  distanceHistory,
  setDistanceHistory,
  height,
  setHeight,
  weight,
  setWeight,
  setMHistoryNotes,
  setGeneralNotes,
  maxStartAt,
  profile,
  setButtonDisabled,
}: IReturnInitiationProps) {
  const commonProps = {
    step: selectedSchema.steps[step],
    setButtonDisabled,
  };

  return (
    <>
      {selectedSchema.type === 'RETURN_INITIATION' && step === 0
                && (
                  <SetSelectedWeekScreen
                    {...commonProps}
                    description="Você pode escolher entre um treino de 4 semanas, que vai te ajudar a correr de 25 a 35 minutos sem parar, ou de 8 semanas, para te ajudar a correr de 35 a 50 minutos sem parar."
                    selectedWeek={selectedWeek}
                    clickCallBack={
                      (value: any) => {
                        setValueForEnableNextButton(() => handleSetSelectedWeek(value));
                      }
                    }
                  />
                )}

      {selectedSchema.type === 'RETURN_INITIATION'
                && step === 1
                && (
                  <SetStartAtScreen
                    {...commonProps}
                    minStartAt={minStartAt}
                    maxStartAt={maxStartAt}
                    startAt={startAt}
                    onChangeValue={
                      (text: string) => setValueForEnableNextButton(() => handleStartAt(text))
                    }
                  />
                )}

      {selectedSchema.type === 'RETURN_INITIATION' && step === 2
                && (
                  <SetDistanceHistoryScreen
                    {...commonProps}
                    distanceHistory={distanceHistory}
                    onClick={
                      (value: any) => setValueForEnableNextButton(() => setDistanceHistory(value))
                    }
                  />
                )}

      {selectedSchema.type === 'RETURN_INITIATION' && step === 3
                && (
                  <SetDaysWeeklyWorkoutScreen
                    {...commonProps}
                    onClick={
                      (value: any) => {
                        setValueForEnableNextButton(() => setDaysWeeklyWorkout(value));
                      }
                    }
                    daysWeeklyWorkout={daysWeeklyWorkout}
                    profile={profile}
                  />
                )}

      {selectedSchema.type === 'RETURN_INITIATION' && step === 4
                && (
                  <SetMeasurementScreen
                    {...commonProps}
                    height={height}
                    weight={weight}
                    onChangeHeight={
                      (text: string) => setValueForEnableNextButton(() => setHeight(text))
                    }
                    onChangeWeight={
                      (text: string) => setValueForEnableNextButton(() => setWeight(text))
                    }
                  />
                )}

      {selectedSchema.type === 'RETURN_INITIATION' && step === 5
                && (
                  <SetNotesScreen
                    {...commonProps}
                    onChangeHistoryNotes={
                      (text: string) => setValueForEnableNextButton(() => setMHistoryNotes(text))
                    }
                    onChangeGeneralNotes={
                      (text: string) => setValueForEnableNextButton(() => setGeneralNotes(text))
                    }
                  />
                )}
    </>
  );
}
