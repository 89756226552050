import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { AiFillLock } from 'react-icons/ai';
import { MdChevronRight } from 'react-icons/md';
import { GoPrimitiveDot } from 'react-icons/go';
import BackArrow from '../BackArrow';
import { RootState } from '../../store';
import FreeUserModal from '../FreeUserModal';
import theme from '../../theme';

import {
  Container, Content, AgendaContainer, ImageButton, InfoContainer, LiveContainer, Live, Title, Info, ItemButton, IconContainer, InfoContent,
} from './style';
import freeUserRedirectLinks from '../../utils/freeUserRedirectLinks';

export default function LiveClasses() {
  const isFree = useSelector((state: RootState) => state.auth.user.isFree);
  const [freeModal, setFreeModal] = useState(false);
  const toggleFreeModal = () => {
    setFreeModal((isOpen) => !isOpen);
  };

  const navigate = useNavigate();
  return (
    <Container data-testid="live-classes-container">

      <BackArrow text="TREINOS AO VIVO" bigger backTo="/club" />
      <Content>
        <AgendaContainer onClick={() => navigate('/club/live-classes/schedule')}>
          <ImageButton>
            <InfoContainer>
              <LiveContainer>
                <Live>
                  <GoPrimitiveDot size={10} color="red" />
                  Ao vivo
                </Live>
              </LiveContainer>
              <Title>
                {isFree && (
                  <>
                    <AiFillLock size={15} color={theme.colors.title} />

                  </>
                )}

                Agenda de treinos ao vivo
              </Title>
              <Info>
                Treinos funcionais, coletivos e ao vivo com os membros do Clube e com o professor.
              </Info>
            </InfoContainer>
            <IconContainer onClick={() => navigate('/club/live-classes/schedule')}>
              <MdChevronRight size={50} color="white" />
            </IconContainer>
          </ImageButton>
        </AgendaContainer>
        <ItemButton>
          <InfoContainer onClick={() => (isFree ? setFreeModal(true) : navigate('/club/RecordedClasses'))}>
            <InfoContent>
              <Title>
                {isFree && (
                  <>
                    <AiFillLock size={15} color={theme.colors.title} />

                  </>
                )}

                Gravações anteriores
              </Title>
              <Info>
                Não pode fazer o treino ao vivo? Assista à gravação, que fica disponível
                aos membros do Clube Corrida Perfeita.
              </Info>

            </InfoContent>

          </InfoContainer>
          <IconContainer onClick={() => (isFree ? setFreeModal(true) : navigate('/club/RecordedClasses'))}>
            <MdChevronRight size={50} color="white" />
          </IconContainer>
        </ItemButton>

      </Content>
      <FreeUserModal
        isModalOpen
        isVisible={freeModal}
        text="As gravações estão disponíveis apenas para membros do Clube Corrida Perfeita."
        link={freeUserRedirectLinks.liveLecture}
        toggle={toggleFreeModal}
      />
    </Container>
  );
}
