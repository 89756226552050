import { createSlice } from '@reduxjs/toolkit';
import toast from 'react-hot-toast';
import { purgeStoredState } from 'redux-persist';
import { AppThunk } from '.';
import api, { api2, LoginApi } from '../services/api';
import { getUrlQueryParams, getGarminToken } from '../utils';
import coreApi from '../services/coreApi';

export type User = {
  id: number;
  name: string;
  username: string | null;
  email: string;
  bio: string | null;
  birth: string | null;
  genre: 'M' | 'F' | 'Masculino' | 'Feminino' | null;
  weight: number | string | null;
  height: number | string | null;
  days_weekly_workout: string | null;
  subscription_type: string | null;
  s_username: string | null;
  s_id: string | number | null;
  s_photo: string | null;
  onboard_status: string | null;
  address: any;
  userZone: any;
  live_lectures_notification: number | boolean | null;
  day_trainings_notification: number | boolean | null;
  garmin_access_token: string | null;
  isFree: boolean | null;
  km5_date: string | null;
  km10_date: string | null;
  km21_date: string | null;
  km42_date: string | null;
  km5_time: number | null;
  km10_time: number | null;
  km21_time: number | null;
  km42_time: number | null;
  weeks_regularity: number;
  uninterrupted_weeks_regularity: number;
  is_breaking_record: boolean;
  week_record: number;
  phone: string | null;
  social_login?: boolean;
  coach_contact_link: string;
  ask_about_activity_shoes?: boolean;
};

interface GoogleLoginProps {
  name: string
  email: string
  photo: string
  token: string
  origin: string
}

const auth = createSlice({
  name: 'auth',
  initialState: {
    token: null,
    loading: false,
    user: {} as User,
    coach: {} as User,
    path: '',
  },
  reducers: {
    logout: (state) => {
      state.token = null;
      state.user = {} as User;
      state.user.uninterrupted_weeks_regularity = 0;
      state.user.is_breaking_record = false;
      state.user.week_record = 0;
      purgeStoredState({ key: 'auth', storage: localStorage });
    },
    loginRequest: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.token = action.payload.token;
      state.user = action.payload.user;
      state.user.isFree = action.payload.user.subscription_type === 'FREE';
      state.user.is_breaking_record = action.payload.user.is_breaking_record;
      state.user.week_record = action.payload.user.week_record;
    },
    getCoachSuccess: (state, action) => {
      state.coach = action.payload;
    },
    loginFinish: (state) => {
      state.loading = false;
    },
    refreshUserRequest: (state) => {
      state.loading = true;
    },
    refreshUserSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.user;
      state.user.isFree = action.payload.user.subscription_type === 'FREE';
      state.user.is_breaking_record = action.payload.user.is_breaking_record;
      state.user.week_record = action.payload.user.week_record;
    },
    refreshUserFail: (state) => {
      state.loading = false;
    },
    updateUserProps: (state, action) => {
      state.user = { ...state.user, ...action.payload };
    },
    routed: (state, action) => {
      state.path = action.payload;
    },
  },
});

export const {
  loginRequest, loginFinish,
  loginSuccess, logout, refreshUserRequest, refreshUserFail,
  refreshUserSuccess, updateUserProps, getCoachSuccess, routed,
} = auth.actions;

export default auth.reducer;

export const login = (email: string, password: string, callback: any): AppThunk => (
  async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await api.post('sessions', { email, password });

      const { token } = response.data.auth;
      const { user } = response.data;

      api.defaults.headers.common = { Authorization: `Bearer ${token}` };
      api2.defaults.headers.common = { Authorization: `Bearer ${token}` };
      coreApi.defaults.headers.common = { Authorization: `Bearer ${token}` };

      dispatch(getCoach());
      dispatch(loginSuccess({ token, user }));
    } catch (error: any) {
      if (error?.message === 'Network Error') {
        callback({ hasError: true, title: 'Internet', message: 'Por favor, verifique sua conexão com a internet.' });
      } else {
        callback({ hasError: true, title: 'Falha na autenticação', message: 'E-mail ou senha não conferem, verifique seus dados e tente novamente.' });
      }
    } finally {
      dispatch(loginFinish());
    }
  }
);

export const getCoach = (): AppThunk => (
  async (dispatch) => {
    await api.get('get_coach')
      .then((response) => {
        if (response.data) {
          dispatch(getCoachSuccess(response.data));
        }
      });
  }
);

export const loginWithGoogle = (data: GoogleLoginProps, callback: any): AppThunk => (
  async (dispatch) => {
    dispatch(loginRequest());

    try {
      const response = await LoginApi.post('google-login', data);

      const { token } = response.data.auth;
      const { user } = response.data;

      api.defaults.headers.common = { Authorization: `Bearer ${token}` };
      api2.defaults.headers.common = { Authorization: `Bearer ${token}` };
      coreApi.defaults.headers.common = { Authorization: `Bearer ${token}` };

      dispatch(loginSuccess({ token, user }));
    } catch (error: any) {
      if (error?.message === 'Network Error') {
        callback({ hasError: true, title: 'Internet', message: 'Por favor, verifique sua conexão com a internet.' });
      } else {
        callback({ hasError: true, title: 'Falha na autenticação', message: 'E-mail ou senha não conferem, verifique seus dados e tente novamente.' });
      }
    } finally {
      dispatch(loginFinish());
    }
  }
);

export const updateProfileProps = (user: any): AppThunk => (
  (dispatch) => {
    dispatch(updateUserProps({ ...user }));
  }
);

export const refreshUser = (userId: number): AppThunk => (
  async (dispatch) => {
    dispatch(refreshUserRequest());
    await api.get(`users/${userId}`)
      .then((response) => {
        if (response.data) {
          const user = response.data;
          dispatch(refreshUserSuccess({ user }));
        }
      })
      .catch(() => {
        dispatch(refreshUserFail());
      });
  }
);

export const loginWithToken = (userId: number, token: string): AppThunk => (
  async (dispatch) => {
    dispatch(loginRequest());

    api.defaults.headers.common = { Authorization: `Bearer ${token}` };
    api2.defaults.headers.common = { Authorization: `Bearer ${token}` };
    coreApi.defaults.headers.common = { Authorization: `Bearer ${token}` };

    await api.get(`users/${userId}`)
      .then((response) => {
        if (response.data) {
          const user = response.data;
          dispatch(loginSuccess({ token, user }));
        }
      }).catch(() => {
        dispatch(loginFinish());
      });
    dispatch(loginFinish());
  }
);

export const integrateGarmin = (url: string): AppThunk => (
  async (dispatch, getState) => {
    try {
      const token = await getGarminToken(url);

      const queryParams = getUrlQueryParams(token);
      const { oauth_token, oauth_token_secret } = queryParams;

      if (
        !oauth_token
        && !oauth_token_secret
      ) throw new Error('Params Error');

      const userId = getState().auth.user.id;

      await api.post(`integrations/${userId}/garmin`, {
        token: oauth_token,
        secret: oauth_token_secret,
      });

      dispatch(refreshUser(+userId));

      toast('Integração Garmin realizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao integrar com a Garmin!');
    }
  }
);

export const removeGarmin = (): AppThunk => (
  async (dispatch, getState) => {
    const userId = getState().auth.user.id;
    try {
      const response = await api.put(`users/${userId}?removeGarminIntegration=true`, {
        garmin_access_token: '',
        garmin_token_secret: '',
      });

      dispatch(refreshUserSuccess({ user: response.data }));
      toast('Integração com a Garmin removida.');
    } catch (err) {
      toast.error('Erro ao remover integração com a Garmin.');
    }
  }
);

export const integrateStrava = (url: string): AppThunk => (
  async (dispatch, getState) => {
    try {
      const queryParams = getUrlQueryParams(url);

      const { code } = queryParams;
      const userId = getState().auth.user.id;

      await api.post(`/s/${userId}?code=${code}`);

      dispatch(refreshUser(+userId));

      toast('Integração com a Strava realizada com sucesso!');
    } catch (err) {
      toast.error('Erro ao integrar com a Strava!');
    }
  }
);

export const removeStrava = (): AppThunk => (
  async (dispatch, getState) => {
    const userId = getState().auth.user.id;
    try {
      const response = await api.put(`users/${userId}?removeStravaIntegration=true`, {
        s_id: '',
        s_username: '',
        s_access_token: '',
        s_refresh_token: '',
      });

      dispatch(refreshUserSuccess({ user: response.data }));
      toast('Integração com a Strava removida.');
    } catch (err) {
      toast.error('Erro ao remover integração com a Strava.');
    }
  }
);
