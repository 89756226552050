import React, { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import whatsApp from '../../../assets/club/zap.svg';
import BackArrow from '../../../components/BackArrow';
import HelpItem from '../../../components/HelpItem';
import { Avatar } from '../../Profile/style';
import {
  AvatarContainer,
  CoachContainer,
  Container, InfoContainer,
} from './styles';
import { RootState } from '../../../store';

import { getCoach } from '../../../store/Auth.store';

export default function CoachAttendance() {
  const auth = useSelector((state: RootState) => state.auth);
  const { coach } = auth;

  const dispatch = useDispatch();

  useEffect(() => {
    if (!coach || !coach.id) {
      dispatch(getCoach() as any);
    }
  }, []);

  const items = [{
    key: 1,
    title: 'Atendimento de treinadores via WhatsApp',
    desc: '',
    image: whatsApp,
    onPress: coach.id ? coach.coach_contact_link : 'https://corridaperfeita.com/whatsapp-clube-essencial',
  }];

  return (
    <Container>
      <BackArrow text="ATENDIMENTO DOS TREINADORES" bigger backTo="/club" />
      <InfoContainer>
        {coach.id
          ? (
            <AvatarContainer>
              {
                coach?.s_photo
                  ? <Avatar src={coach?.s_photo} />
                  : <></>
              }
              {
                (coach
                  ? (
                    <CoachContainer>
                      <p>
                        {coach?.name}
                        {' '}
                        é responsável por seu atendimento no Clube Corrida Perfeita Essencial e está disponível no WhatsApp para te ajudar em dúvidas sobre seus treinos, objetivos, uso da plataforma, dentre outros.
                      </p>
                    </CoachContainer>
                  )
                  : <></>
                )
              }
            </AvatarContainer>
          ) : (
            <p>
              Nossos treinadores estão disponíveis via WhatsApp e via e-mail,
              para lhe ajudar em dúvidas diversas sobre seus treinos, sobre corrida
              e uso da plataforma.
            </p>
          )}
      </InfoContainer>
      {items.map((item) => <HelpItem key={item.key} content={item} />)}
    </Container>
  );
}
