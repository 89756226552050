import React from 'react';
import { FiCopy, FiLock } from 'react-icons/fi';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { MdClose } from 'react-icons/md';
import {
  InfoText, Header,
  ModalContentButtonTextCancel,
  ModalContent,
  ModalContentButtonContainer,
  ModalContentButton,
  ModalBackDrop,
  Text,
  CouponContainer,
  CouponContent,
  CouponText,
  CopyClick,
  Site,
  DiscountValue,
  CouponCodeButton,
  InfoText2,
  Divider,
} from './styles';
import {
  ModalDialog,
  ModalContainer,
} from '../Modal/Modal.styles';
import RaceCalendarContext from '../../pages/RaceCalendar/Context/RaceCalendarContext';
import { ICoupon, IRace } from '../../store/RaceCalendar.store';
import { RootState } from '../../store';

export interface IRaceCouponModalProps{
  race: IRace;
  localOfRaces: string;
  coupons: ICoupon[];
  isOpen: any;
  toggle: any;
  kilometers: string;
  raceUrl?: string;
}

function DiscountRender({ discountType, discount }: ICoupon) {
  return (
    <DiscountValue>
      {
        (discountType === 'ABSOLUTE' && (
          <>
            R$
            {' '}
            {discount}
            {' '}
            OFF
          </>
        ))
        || (discountType === 'PERCENTAGE' && (
          <>
            {discount}
            % OFF
          </>
        ))
      }
    </DiscountValue>
  );
}

export default function RaceCouponModal({
  isOpen, toggle, coupons, race, localOfRaces, kilometers, raceUrl,
}: IRaceCouponModalProps) {
  const { SendRDEvent } = React.useContext(RaceCalendarContext).rdTracking;
  const { user } = useSelector((state: RootState) => state.auth);
  const nonMemberCoupons = coupons.filter((coupon) => !coupon.member);
  const memberCoupons = coupons.filter((coupon) => coupon.member);
  const userIsMember = React.useMemo(() => (user && user.subscription_type && user.subscription_type !== 'FREE'), [user]);

  const handleCopyClick = (coupon: ICoupon) => {
    const textToCopy = coupon.code;

    SendRDEvent(race, coupon);

    navigator.clipboard.writeText(textToCopy)
      .then(() => {
        toast.success('Cupom copiado com sucesso!');
      })
      .catch(() => {
        toast.error('Erro ao copiar o cupom.');
      });
  };

  const handleNonMemberClick = (coupon: ICoupon) => {
    toast.error('Esse cupom é só para membros do clube Corrida Perfeita.');

    SendRDEvent(race, { ...coupon, code: 'Cupom exclusivo para membros do clube' });
  };

  return (
    isOpen && (

      <>
        <ModalContainer>
          <ModalDialog>
            <MdClose className="close-btn" onClick={toggle} />
            <ModalContent>
              <Header>Desconto Desbloqueado!</Header>
              <InfoText>
                {race.name}
              </InfoText>
              <Text>
                {localOfRaces}
              </Text>
              <Text>
                {kilometers}
              </Text>
              <CouponContainer>
                <Text style={{ fontSize: 10, marginBottom: 10 }}>Copie o código abaixo e use no site da prova</Text>
                {nonMemberCoupons.map((coupon) => (
                  <CouponContent key={coupon.id}>
                    {DiscountRender(coupon)}
                    <CouponCodeButton>
                      <CouponText onClick={() => SendRDEvent(race, coupon)}>
                        {coupon.code}
                      </CouponText>
                      <CopyClick onClick={() => handleCopyClick(coupon)}>
                        COPIAR
                        <FiCopy size={18} color="#B4B4B4" style={{ paddingLeft: 5 }} />
                      </CopyClick>
                    </CouponCodeButton>
                  </CouponContent>
                ))}
                {
                  nonMemberCoupons.length > 0 && memberCoupons.length > 0 && (
                    <>
                      <Divider />
                      <InfoText2>
                        Cupons para membros do clube:
                      </InfoText2>
                    </>
                  )
                }
                {memberCoupons.map((coupon) => (
                  <CouponContent key={coupon.id}>
                    {userIsMember && (
                      DiscountRender(coupon)
                    )}
                    <CouponCodeButton
                      cursor={userIsMember ? '' : 'pointer'}
                      onClick={userIsMember ? () => {} : () => handleNonMemberClick(coupon)}
                      specialCoupon
                    >
                      <CouponText>
                        {userIsMember ? coupon.code : 'SÓ PARA MEMBROS'}
                      </CouponText>
                      {userIsMember ? (
                        <CopyClick onClick={userIsMember ? () => handleCopyClick(coupon) : () => handleNonMemberClick(coupon)}>
                          COPIAR
                          <FiCopy size={18} color="#B4B4B4" style={{ paddingLeft: 5 }} />
                        </CopyClick>
                      ) : (
                        <CopyClick>
                          <FiLock size={25} color="#B4B4B4" />
                        </CopyClick>

                      )}
                    </CouponCodeButton>
                  </CouponContent>
                ))}
                {raceUrl && (
                  <Site onClick={() => window.open(raceUrl, '_blank')}>
                    IR PARA O SITE DA PROVA
                  </Site>
                )}

              </CouponContainer>
              <ModalContentButtonContainer>
                <ModalContentButton onClick={toggle}>
                  <ModalContentButtonTextCancel>Voltar para a Lista</ModalContentButtonTextCancel>
                </ModalContentButton>

              </ModalContentButtonContainer>
            </ModalContent>
          </ModalDialog>
        </ModalContainer>
        <ModalBackDrop />
      </>
    )
  );
}
