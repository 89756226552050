const freeUserRedirectLinks = {
  weightTraining: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-treinos-fortalecimento&utm_medium=app-corrida-perfeita&utm_source=app',
  drills: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-treinos-educativos&utm_medium=app-corrida-perfeita&utm_source=app',
  liveLecture: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-treinos-aovivo&utm_medium=app-corrida-perfeita&utm_source=app',
  cardio: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-treinos-cardio&utm_medium=app-corrida-perfeita&utm_source=app',
  others: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-treinos-alongamento&utm_medium=app-corrida-perfeita&utm_source=app',
  classes: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-video-aulas&utm_medium=app-corrida-perfeita&utm_source=app',
  events: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-agenda-treinos-aovivo&utm_medium=app-corrida-perfeita&utm_source=app',
  trainingPlan: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-plano-treinos&utm_medium=app-corrida-perfeita&utm_source=app',
  zones: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-zonas&utm_medium=app-corrida-perfeita&utm_source=app',
  help: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-atendimento-treinadores&utm_medium=app-corrida-perfeita&utm_source=app',
  askQuestions: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-plantao-duvidas&utm_medium=app-corrida-perfeita&utm_source=app',
  cashback: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-cashback&utm_medium=app-corrida-perfeita&utm_source=app',
  telegram: 'https://corridaperfeita.com/clube/?utm_campaign=appwall-grupos-telegram&utm_medium=app-corrida-perfeita&utm_source=app',
  raceCalendar: 'https://corridaperfeita.com/clube/?utm_campaign=racecalendar&utm_medium=app-corrida-perfeita&utm_source=app',
  referralCoupon: 'https://cperfeita.run/desconto-indicacao',
};
export default freeUserRedirectLinks;
