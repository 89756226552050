import styled from 'styled-components';
import theme from '../../theme';

export const Card = styled.section`
  display: block;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 13px;
  margin-top: 5px;
  padding: 10px;
  margin-left: 10px;
  background-color: ${theme.colors.primary3};
  border-radius: 10px;
  width: 100%;
  min-height: 250px;
`;

export const CardTitle = styled.section`
  color: ${theme.colors.gray};
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
`;
