import React from 'react';
import { AiFillLock } from 'react-icons/ai';
import { useNavigate } from 'react-router-dom';

import { useSelector } from 'react-redux';
import { RootState } from '../../../store';

import {
  Container, InfoContainer, TitleContainer, IconContainer,
} from './styles';

interface Props {
  source: any,
  title: string,
  description: string,
  type: String,
  blockForFree: boolean,
}

function Item({
  source, title, description, type, blockForFree,
}:Props) {
  const user = useSelector((state: RootState) => state.auth.user);
  const { isFree } = user;
  const navigate = useNavigate();

  const handlePress = () => {
    if (type === 'LIVE_LECTURE') {
      navigate('/club/live-classes');
    } else {
      navigate(`/exerciseList/${type}`);
    }
  };

  return (
    <Container
      style={{ backgroundImage: `url(${source})` }}
      onClick={() => handlePress()}
    >
      <InfoContainer>
        <TitleContainer>
          {(isFree && blockForFree) && (
            <IconContainer>
              <AiFillLock />
            </IconContainer>
          )}
          <h2>{title}</h2>
        </TitleContainer>
        <p>{description}</p>
      </InfoContainer>
    </Container>
  );
}

export default Item;
