import React from 'react';

import BackArrow from '../BackArrow';
import {
  Button, ButtonText, Content, Container, TextContainer, TextInfo, Ol, Li,
} from './style';

export default function Store() {
  return (

    <Container data-testid="store-container">
      <BackArrow text="PRODUTOS CORRIDA PERFEITA" bigger backTo="/club" />
      <Content>
        <TextContainer>
          <TextInfo>
            Na nossa loja virtual, temos uma linha exclusiva de produtos, incluindo camisetas, bermudas, bonés, viseiras, dentre outros itens para corrida.
            <br />
            <br />
            <br />
            Como membro do Clube, você tem um ótimo desconto em todos os produtos.

            <br />
            <br />

            Veja como obter os descontos:
            <br />
            <br />
            <Ol>
              <Li>Acesse a loja no botão abaixo;</Li>
              <Li>Faça login com seu mesmo e-mail de acesso ao Clube (já sua senha padrão inicial é seu CPF somente números);</Li>
              <Li>Veja os novos valores disponíveis e faça sua compra.</Li>
            </Ol>
          </TextInfo>
        </TextContainer>
        <Button onClick={() => window.open('https://www.lojacorridaperfeita.com.br/')}>
          <ButtonText>ir para loja exclusiva</ButtonText>
        </Button>
      </Content>
    </Container>
  );
}
