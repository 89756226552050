import React, { useState } from 'react';
import { BsChevronLeft, BsChevronRight } from 'react-icons/bs';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import theme from '../../theme';
import freeUserRedirectLinks from '../../utils/freeUserRedirectLinks';
import FreeUserModal from '../FreeUserModal';
import { ControlButton, ControlPanel, ControlText } from './styles';

interface ClassExecutionControlPanelI {
  isFirst: boolean
  name?: string,
  nextLecture: () => void
  previousLecture: () => void
}

const LAST_FREE_CLASS = 'A passada na corrida';

function ClassExecutionControlPanel({
  isFirst,
  name,
  nextLecture,
  previousLecture,
}: ClassExecutionControlPanelI) {
  const [freeModal, setFreeModal] = useState(false);

  const isFree = useSelector((state: RootState) => state.auth.user.isFree);
  const blockNext = name === LAST_FREE_CLASS;

  const handleNext = () => {
    if (isFree && blockNext) {
      setFreeModal(true);
      return;
    }
    nextLecture();
  };

  return (
    <>
      <ControlPanel isFirst={isFirst}>
        {
          !isFirst
              && (
                <ControlButton onClick={previousLecture}>
                  <BsChevronLeft size={25} color={theme.colors.title} />
                  <ControlText>
                    ANTERIOR
                  </ControlText>
                </ControlButton>
              )
        }
        <ControlButton onClick={handleNext}>
          <ControlText>
            PRÓXIMA
          </ControlText>
          <BsChevronRight size={25} color={theme.colors.title} />
        </ControlButton>
      </ControlPanel>
      {isFree && (
        <FreeUserModal
          isModalOpen
          isVisible={freeModal}
          text="Esta série de aulas está disponível apenas para membros do Clube Corrida Perfeita."
          link={freeUserRedirectLinks.classes}
          toggle={() => setFreeModal(false)}
        />
      )}
    </>
  );
}

export default ClassExecutionControlPanel;
