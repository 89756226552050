import { stateNames } from '../pages/RaceCalendar';

export function formatSubscriptionStatus(status: any) {
  switch (status) {
    case 'active':
      return 'ATIVA';

    case 'canceled':
      return 'CANCELADA';

    case 'finished':
      return 'FINALIZADA';

    case 'pending':
      return 'PENDENTE';
    default:
      return '--';
  }
}

export function hasAccess(user: any, types: string[]) {
  return types.some((t) => t === user.subscription_type);
}

type OptionsType = {
  [key: string]: { title: string, className: string }
}

export const formatBillStatus: OptionsType = {
  paid: {
    title: 'PAGA',
    className: 'text-success',
  },
  pending: {
    title: 'PENDENTE',
    className: 'text-warning',
  },
  review: {
    title: 'PENDENTE',
    className: 'text-warning',
  },
  scheduled: {
    title: 'PENDENTE',
    className: 'text-warning',
  },
  processing: {
    title: 'PENDENTE',
    className: 'text-warning',
  },
  canceled: {
    title: 'CANCELADA',
    className: 'text-danger',
  },
};

export function isAdm(userID: any) {
  return (
    userID === 1
    || userID === 2
    || userID === 3
    || userID === 4
    || userID === 5
    || userID === 8
    || userID === 10
    || userID === 2966
    || userID === 4283
  );
}

export default function dateMask(value: string) {
  return value
    .replace(/\D/g, '')
    .replace(/(\d{2})(\d)/, '$1/$2')
    .replace(/(\d{2})(\d)/, '$1/$2');
}

export function acronymToNormalizedState(state: string) {
  return stateNames[state.toUpperCase()].normalize('NFD').replace(/[\u0300-\u036f]/g, '').toLowerCase().replaceAll(' ', '-');
}
