import React, { useMemo } from 'react';
import html2canvas from 'html2canvas';
import { useLocation } from 'react-router-dom';
import { format } from 'date-fns';
import iconRide from '../assets/activity/icone-bike.png';
import iconRun from '../assets/activity/icone-corrida.png';
import iconSwin from '../assets/activity/icone-natacao.png';
import iconWeight from '../assets/activity/icone-exercicio.png';
import iconDrill from '../assets/activity/icone-educativo.png';
import iconOther from '../assets/activity/icone-outros.png';
import api from '../services/api';

export enum TrainingType {
  RUN = 'RUN',
  WEIGHTTRAINING = 'WEIGHTTRAINING',
  WORKOUT = 'WORKOUT',
  RIDE = 'RIDE',
  SWIM = 'SWIM',
  DRILLS = 'DRILLS',
  CARDIO = 'CARDIO',
  OTHERS = 'OTHERS',
  DURATION = 'DURATION'
}

export function formatTrainingIcon(type: string) {
  let currIcon = null;

  switch (type) {
    case TrainingType.RUN:
      currIcon = iconRun;
      break;
    case TrainingType.WEIGHTTRAINING:
      currIcon = iconWeight;
      break;
    case TrainingType.WORKOUT:
      currIcon = iconWeight;
      break;
    case TrainingType.RIDE:
      currIcon = iconRide;
      break;
    case TrainingType.SWIM:
      currIcon = iconSwin;
      break;
    case TrainingType.DRILLS:
      currIcon = iconDrill;
      break;
    default:
      currIcon = iconOther;
      break;
  }

  return (
    <img
      src={currIcon}
      alt="Treino de Corrida"
      style={{ width: 20, height: 20 }}
    />
  );
}

export function formatActivityTypeIcon(type: string) {
  switch (type) {
    case 'Run':
      return iconRun;

    case 'Ride':
      return iconRide;

    case 'Swim':
      return iconSwin;

    case 'WeightTraining':
      return iconWeight;
    case 'Walk':
      return iconRun;
    case 'Drills':
      return iconDrill;

    default:
      return iconOther;
  }
}

export function formatDuration(duration: number, type: string) {
  let hoursStr = '';
  let minutesStr = '';
  let secondsStr = '';

  let hours = 0;
  let minutes = 0;
  let sec = 0;

  try {
    if (duration && duration > 0) {
      if (duration >= 60) {
        hours = duration / 60;
        minutes = (hours % 1) * 60;
      } else {
        minutes = duration;
      }
      sec = (minutes % 1) * 60;

      hoursStr = hours < 10 ? `0${Math.floor(hours)}` : `${Math.floor(hours)}`;
      minutesStr = minutes < 10 ? `0${Math.floor(minutes)}` : `${Math.floor(minutes)}`;
      secondsStr = sec < 10 ? `0${Math.floor(sec)}` : `${Math.floor(sec)}`;

      switch (type) {
        case 'time':
          return `${hoursStr}:${minutesStr}:${secondsStr}`;
        case 'pace':
          return `${minutesStr}:${secondsStr}`;
        case 'edit':
          return {
            hours: Math.floor(hours),
            minutes: Math.floor(minutes),
            seconds: Math.floor(sec),
          };
        case 'editPace':
          return {
            minutes: Math.floor(minutes),
            seconds: Math.floor(sec),
          };
        default: {
          if (hours > 0) {
            return `${hoursStr}h ${minutesStr}m`;
          }
          return `${minutesStr}m ${secondsStr}s`;
        }
      }
    }
  } catch (error) {
    return '--';
  }
  return '--';
}

export function formatActivityDate(date: string) {
  return format(new Date(date), 'dd/MM');
}

export function formatDistance(distance: number) {
  if (distance) {
    const distanceTotal = distance * 1000;

    const result = distanceTotal < 1000 ? `${distanceTotal}m` : `${distance} km`;

    return result;
  }

  return '-';
}

export function objectExists(obj: any) {
  if (obj) {
    return Object.keys(obj).length > 0;
  }

  return false;
}

export function getUrlQueryParams(url: string) {
  const paramsList = url.replace(url.slice(0, url.indexOf('?') + 1), '');
  const queryParams: { [param: string]: string } = {};
  paramsList.split('&').forEach((param) => {
    const [key, value] = param.split('=');
    queryParams[key] = value;
  });

  return queryParams;
}

export async function getGarminToken(url: string) {
  const deepLinkQueryParams = getUrlQueryParams(url);
  const {
    oauth_verifier,
    oauth_token,
    oauth_token_secret,
  } = deepLinkQueryParams;

  if (
    !oauth_verifier
    && !oauth_token
    && !oauth_token_secret
  ) throw new Error('Params Error');

  const response = await api.post('integrations/garmin/getGarminAccessToken', {
    oauth_verifier,
    oauth_token,
    oauth_token_secret,
  });

  const { token } = response.data;

  return token;
}

export function useQuery() {
  const { search } = useLocation();

  return useMemo(() => new URLSearchParams(search), [search]);
}

export const exportAsImage = async (el: HTMLElement) => {
  const canvas = await html2canvas(el, {
    useCORS: true,
  });
  return canvas.toDataURL('image/png', 1.0);
};

export function currentTimezoneName() {
  return Intl.DateTimeFormat().resolvedOptions().timeZone;
}

export const formatRequestKeepFitType = (value: string) => {
  switch (value) {
    case 'Quero apenas manter minha forma':
      return 'manutenção nos';
    case 'Tenho um objetivo próximo com data marcada':
      return 'manutenção com objetivo nos';
    default:
      return '';
  }
};

export const formatTrainingPlanRequestObjective = (value: string) => {
  switch (value) {
    case 'quero melhorar':
      return 'melhorar nos';
    case 'primeira vez':
      return 'completar os';
    default:
      return '';
  }
};

export function formatStateNameToUrl(name: string) {
  return name.normalize('NFD').replace(/[\u0300-\u036f]/g, '').replaceAll(' ', '-').toLowerCase();
}

export const getGenre = (value: string) => {
  if (!value) return 'male';

  if (['m', 'masculino', 'masc'].some((item) => item === value.toLowerCase())) {
    return 'male';
  } if (['f', 'feminino', 'fem'].some((item) => item === value.toLowerCase())) {
    return 'female';
  }

  return 'male';
};
