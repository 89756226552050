import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ModalNotifications from '../ModalNotification';
import { MessageText } from './styles';
import Skeleton from '../Skeleton';
import CardComponent from './CardComponent';
import {
  fetchNotifications, deleteNotification, markAllAsRead, markAsRead, INotification,
} from '../../../../store/Notifications.Store';
import { RootState } from '../../../../store';

function CardNotifications() {
  const dispatch = useDispatch();

  const { loading, notifications } = useSelector((state: RootState) => state.notifications);

  const [visible, setVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState<INotification | undefined>(undefined);

  const requestNotifications = async () => {
    dispatch(fetchNotifications() as any);
  };

  useEffect(() => {
    requestNotifications();
  }, []);

  const handleDeleteCard = async (data: INotification) => {
    dispatch(deleteNotification(data._id) as any);
  };

  const handleMarkAsRead = async (data: INotification) => {
    dispatch(markAsRead(data._id) as any);
  };

  const handleMarkAllAsViewed = async () => {
    dispatch(markAllAsRead() as any);
  };

  const handleCardSelect = async (data: any) => {
    setSelectedCard(data);
    setVisible(true);

    handleMarkAsRead(data);
  };

  return (
    <>
      {
        loading
        && (
          <>
            <Skeleton />
          </>
        )
      }

      {
        !loading
        && notifications.length === 0
        && (
          <MessageText>
            Sua central de notificações está vazia.
            {'\n'}
            Em breve você receberá novas mensagens
            {' '}
            {`${':)'}`}

          </MessageText>
        )
      }

      {
        !loading
        && notifications.length > 0
        && (
          <>
            {notifications.map((notification: any) => (
              <CardComponent
                notification={notification}
                handleDeleteCard={handleDeleteCard}
                handleMarkAsRead={handleMarkAsRead}
                handleCardSelect={handleCardSelect}
                handleMarkAllAsViewed={handleMarkAllAsViewed}
              />
            ))}

            <ModalNotifications
              visible={visible}
              selectedCard={selectedCard}
              setVisible={setVisible}
            />
          </>
        )
      }
    </>
  );
}

export default CardNotifications;
