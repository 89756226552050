import React, { useEffect, useState } from 'react';
import { format } from 'date-fns';
import { IoMdInformationCircleOutline } from 'react-icons/io';
import BackArrow from '../BackArrow';
import Loader from '../Loader';
import api from '../../services/api';

import {
  Container, HeaderContainer, ItemsContainer,
  LinkText, LinkTextButton, TextInfo, TextInfoContainer,
} from './style';
import LiveClassItem from '../LiveClassItem';
import LiveClassInfoModal from '../LiveClassInfoModal';
import FreeUserModal from '../FreeUserModal';
import freeUserRedirectLinks from '../../utils/freeUserRedirectLinks';

export default function LiveClassSchedule() {
  const [loading, setLoading] = useState<boolean>(false);
  const [classes, setClasses] = useState<any>([]);
  const [infoModal, setInfoModal] = useState<boolean>(false);
  const [freeModal, setFreeModal] = useState(false);

  const getClasses = async () => {
    setLoading(true);
    const today = format(new Date(), 'yyyy-MM-dd');
    await api.get(`live_lectures?clone_type=SCHEMA&date=${today}&obj_type=LECTURE&isRecord=false`)
      .then((response) => {
        if (response) {
          setClasses(response.data.data);
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };
  useEffect(() => {
    getClasses();
  }, []);

  const toggleModal = () => {
    setInfoModal((isOpen) => !isOpen);
  };

  const toggleFreeModal = () => {
    setFreeModal((isOpen) => !isOpen);
  };

  return (
    <Container data-testid="live-class-schedule-container">
      <LiveClassInfoModal
        isOpen={infoModal}
        toggle={toggleModal}
        confirmText="ok"
      />
      <HeaderContainer>
        <BackArrow text="AGENDA DE TREINOS AO VIVO" bigger backTo="/club/live-classes" />
        <LinkTextButton onClick={toggleModal}>
          <IoMdInformationCircleOutline size={20} color="white" />
          <LinkText>Como funciona</LinkText>
        </LinkTextButton>
      </HeaderContainer>
      <ItemsContainer>
        {loading
          ? <Loader />
          : classes.map((liveClass: any) => (
            <LiveClassItem
              liveClass={liveClass}
              key={liveClass.id}
              openFreeModal={() => setFreeModal(true)}
            />
          ))}
      </ItemsContainer>
      <TextInfoContainer>
        <TextInfo>Todos os treinos são no horário de Brasília, Brasil [UTM -3].</TextInfo>
      </TextInfoContainer>
      <FreeUserModal
        isModalOpen
        isVisible={freeModal}
        text="Os treinos ao vivo estão disponíveis apenas para membros do Clube Corrida Perfeita."
        link={freeUserRedirectLinks.liveLecture}
        toggle={toggleFreeModal}
      />
    </Container>
  );
}
