import {
  Action, combineReducers, configureStore, ThunkAction, PreloadedState,
} from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import { persistStore, persistReducer } from 'redux-persist';

import localStorage from 'redux-persist/lib/storage';

import authReducer from './Auth.store';
import activitiesReducer from './Activities.store';
import trainingPlanReducer from './TrainingPlan.store';
import eventReducer from './Events.store';
import subscriptionReducer from './Subscription.store';
import classesReducer from './Classes.store';
import TrainingCalendarReducer from './TrainingCalendar.store';
import trainingPlanRequestsReducer from './TrainingPlanRequests.store';
import TrainingDayReducer from './TrainingDay.store';
import exercisesReducer from './Exercises.store';
import exercisesListReducer from './ExercisesList.store';
import sharedNavigationPropsReducer from './SharedNavigationProps.store';
import calendarDataReducer from './CalendarProgress.store';
import notificationsReducer from './Notifications.Store';
import permissionsReducer from './Permissions.store';
import raceCalendarReducer from './RaceCalendar.store';
import predictionReducer from './Prediction.store';

const persistConfig = {
  key: 'cpfu@',
  storage: localStorage,
  whitelist: ['auth', 'classes'],
};

export const rootReducer = combineReducers({
  auth: authReducer,
  activities: activitiesReducer,
  trainingPlan: trainingPlanReducer,
  events: eventReducer,
  exercises: exercisesReducer,
  exercisesList: exercisesListReducer,
  subscription: subscriptionReducer,
  classes: classesReducer,
  trainingCalendar: TrainingCalendarReducer,
  notifications: notificationsReducer,
  trainingPlanRequests: trainingPlanRequestsReducer,
  trainingDay: TrainingDayReducer,
  sharedNavigationProps: sharedNavigationPropsReducer,
  calendarData: calendarDataReducer,
  permissions: permissionsReducer,
  raceCalendar: raceCalendarReducer,
  prediction: predictionReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware({
    serializableCheck: false,
    immutableCheck: false,
  }),
});

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
}

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>;
export type AppStore = ReturnType<typeof setupStore>
export type AppDispatch = typeof store.dispatch;
export type AppThunk = ThunkAction<void, RootState, null, Action<string>>;
export const useAppDispatch = () => useDispatch<AppDispatch>();

export default store;
