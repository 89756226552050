import React, { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import api, { STRAVA_URL } from '../../services/api';
import { RootState } from '../../store';
import { removeGarmin, removeStrava } from '../../store/Auth.store';
import IntegrationContent from '../IntegrationContent';
import Loader from '../Loader';
import { Card, CardTitle } from './style';

export default function Integration() {
  const dispatch = useDispatch<any>();
  const profile = useSelector((state: RootState) => state.auth.user);
  const [loading, setLoading] = useState<boolean>(true);

  useEffect(() => {
    setLoading(false);
  }, []);

  const chooseIntegration = [
    {
      title: 'STRAVA',
      id: 1,
      description: 'Receba no Corrida Perfeita atividades salvas no Strava, inclusive vindos de seu relógio.',
      enabled: !!profile.s_id,
      paragraphs: [
        'Esta integração permite registrar no Corrida Perfeita todos os treinos realizados ou registrados pelo app Strava.',
        'Isso inclui tanto os treinos registrados pelo GPS do Strava, quanto os treinos que o Strava recebe do seu relógio Polar, Apple Watch, TomTom, Amazfit, Samsung, dentre outros.',
      ],
      addIntegration: async (dismissIntegration?: () => void) => {
        try {
          const { host } = window.location;

          const STRAVA_URI = `${STRAVA_URL}?client_id=41754&response_type=code&approval_prompt=force&scope=activity:read_all&redirect_uri=${window.location.protocol}//${host}/profile/edit`;

          window.location.href = STRAVA_URI;
        } catch (err) {
          dismissIntegration?.();
          toast.error('Ocorreu um erro ao integrar com a Strava, tente novamente.');
        }
      },
      removeIntegration: () => {
        dispatch(removeStrava());
      },
    },
    {
      title: 'GARMIN',
      id: 2,
      description: 'Receba no Corrida Perfeita atividas feitas em seu Garmin, bem como envie para seu relógios os treinos planejados pelo Corrida Perfeita.',
      enabled: !!profile.garmin_access_token,
      paragraphs: [
        'A nossa integração com a Garmin permite:',
        '1- Transferir para o Corrida Perfeita os treinos realizados em seu Garmin.',
        '2 - Enviar os treinos de corrida planejados no Corrida Perfeita, direto para seu Garmin Connect.',
        'Para ativar as duas possibilidades acima, marque todas as opções na tela de integração no site da Garmin.',
        'Atenção: Caso receba os treinos direto da Garmin, evite deixar ativada a integração com o Strava, para não haver duplicidade de registros aqui no Corrida Perfeita.',
      ],
      addIntegration: async (dismissIntegration?: () => void) => {
        try {
          const { hostname } = window.location;
          const response = await api.get(`integrations/garmin/getGarminRequestToken?hostName=${hostname}`);

          if (response.data && response.data.url) {
            const responseURL = new URL(response.data.url);
            const redirectURL = new URL(responseURL.searchParams.get('oauth_callback') ?? '');

            redirectURL.pathname = redirectURL.pathname.replace('/perfil', '/profile/edit');
            redirectURL.searchParams.set('field', 'integracoes');

            redirectURL.host = window.location.host;
            responseURL.searchParams.set('oauth_callback', redirectURL.toString());

            window.location.href = responseURL.toString();
          } else {
            dismissIntegration?.();
            toast.error('Ocorreu um erro ao integrar com a Garmin, tente novamente.');
          }
        } catch (err) {
          dismissIntegration?.();
          toast.error('Ocorreu um erro ao integrar com a Garmin, tente novamente.');
        }
      },
      removeIntegration: () => {
        dispatch(removeGarmin());
      },
    },
  ];

  return (
    <Card>
      <CardTitle>INTEGRAÇÕES</CardTitle>
      {
        chooseIntegration.map((integration) => (loading
          ? <Loader key={Math.random()} />
          : <IntegrationContent key={integration.id} {...integration} />))
      }
    </Card>
  );
}
