import React from 'react';
import { User } from '../../../store/Auth.store';
import {
  SetDistanceScreen,
  SetDistanceHistoryScreen,
  SetStartAtScreen,
  SetTargetDateScreen,
  SetDaysWeeklyWorkoutScreen,
  SetMeasurementScreen,
  SetNotesScreen,
  SetLastRecordScreen,
  SetRecordsScreen,
} from '../CommonScreens';
import { ITrainingRequestScreen } from '../interface';

export interface IDistanceProps extends ITrainingRequestScreen {
  handleSetDistance: (value: number) => void,
  distance: number | null,
  setDistanceHistory: (value: any) => void,
  distanceHistory: any,
  handleTargetDate: (date: string) => void,
  daysWeeklyWorkout: string | null,
  setHeight: (value: string) => void,
  height: string,
  setWeight: (value: string) => void,
  weight: string | null,
  minTargetDate: Date,
  targetDate: Date | undefined,
  maxTargetDate: Date,
  minWeek: number,
  maxWeek: number,
  profile: User,
  setDaysWeeklyWorkout: (value: string | null) => void,
  lastRecord: undefined | number,
  setLastRecord: React.Dispatch<React.SetStateAction<number | undefined>>,
  requiredLastRecordDistance: number,
  getKmTime: (type: number) => string | null,
  getKmDate: (type: number) => string | null,
  setKmTime: (type: number, value: string) => void,
  setKmDate: (type: number, value: string) => void,
}

export default function Distance({
  selectedSchema,
  step,
  setValueForEnableNextButton,
  handleSetDistance,
  distance,
  setDistanceHistory,
  distanceHistory,
  handleTargetDate,
  startAt,
  handleStartAt,
  setDaysWeeklyWorkout,
  daysWeeklyWorkout,
  setHeight,
  height,
  setWeight,
  weight,
  setMHistoryNotes,
  setGeneralNotes,
  minStartAt,
  minTargetDate,
  targetDate,
  maxStartAt,
  maxTargetDate,
  minWeek,
  maxWeek,
  profile,
  setButtonDisabled,
  lastRecord,
  setLastRecord,
  requiredLastRecordDistance,
  setKmTime,
  setKmDate,
  getKmTime,
  getKmDate,
}: IDistanceProps) {
  return (
    <>
      {selectedSchema.type === 'DISTANCE' && step === 0
        && (
          <SetDistanceScreen
            step={selectedSchema.steps[step]}
            setButtonDisabled={setButtonDisabled}
            distance={distance}
            onClick={(value: any) => setValueForEnableNextButton(() => handleSetDistance(value))}
          />
        )}

      {selectedSchema.type === 'DISTANCE' && step === 1
        && (
          <SetDistanceHistoryScreen
            step={selectedSchema.steps[step]}
            setButtonDisabled={setButtonDisabled}
            distanceHistory={distanceHistory}
            onClick={(value: any) => setValueForEnableNextButton(() => setDistanceHistory(value))}
          />
        )}

      {selectedSchema.type === 'DISTANCE' && step === 2
        && (
          <SetLastRecordScreen
            step={selectedSchema.steps[step]}
            setLastRecord={setLastRecord}
            lastRecord={lastRecord}
            selectedDistance={distance}
            requiredLastRecordDistance={requiredLastRecordDistance}
            distance={distance}
          />
        )}

      {selectedSchema.type === 'DISTANCE' && step === 3
        && (
          <SetRecordsScreen
            step={selectedSchema.steps[step]}
            setButtonDisabled={setButtonDisabled}
            getKmDate={getKmDate}
            getKmTime={getKmTime}
            distance={distance}
            onChangeDate={
              (value: any, text: string) => {
                setValueForEnableNextButton(() => setKmDate(value, text));
              }
            }
            onChangeTime={
              (value: any, text: string) => {
                setValueForEnableNextButton(() => setKmTime(value, text));
              }
            }
            profile={profile}
          />
        )}

      {selectedSchema.type === 'DISTANCE' && step === 4
        && (
          <SetStartAtScreen
            step={selectedSchema.steps[step]}
            setButtonDisabled={setButtonDisabled}
            minStartAt={minStartAt}
            maxStartAt={maxStartAt}
            startAt={startAt}
            onChangeValue={(text: string) => setValueForEnableNextButton(() => handleStartAt(text))}
          />
        )}

      {selectedSchema.type === 'DISTANCE' && step === 5
        && (
          <SetTargetDateScreen
            step={selectedSchema.steps[step]}
            setButtonDisabled={setButtonDisabled}
            showStepDescription
            targetDate={targetDate}
            minTargetDate={minTargetDate}
            maxTargetDate={maxTargetDate}
            onChangeValue={
              (text: string) => setValueForEnableNextButton(() => handleTargetDate(text))
            }
            minWeek={minWeek}
            maxWeek={maxWeek}
          />
        )}

      {selectedSchema.type === 'DISTANCE' && step === 6
        && (
          <SetDaysWeeklyWorkoutScreen
            step={selectedSchema.steps[step]}
            setButtonDisabled={setButtonDisabled}
            onClick={(value: any) => setValueForEnableNextButton(() => setDaysWeeklyWorkout(value))}
            daysWeeklyWorkout={daysWeeklyWorkout}
            profile={profile}
          />
        )}

      {selectedSchema.type === 'DISTANCE' && step === 7
        && (
          <SetMeasurementScreen
            step={selectedSchema.steps[step]}
            setButtonDisabled={setButtonDisabled}
            height={height}
            weight={weight}
            onChangeHeight={(text: string) => setValueForEnableNextButton(() => setHeight(text))}
            onChangeWeight={(text: string) => setValueForEnableNextButton(() => setWeight(text))}
          />
        )}

      {selectedSchema.type === 'DISTANCE' && step === 8
        && (
          <SetNotesScreen
            step={selectedSchema.steps[step]}
            setButtonDisabled={setButtonDisabled}
            onChangeHistoryNotes={
              (text: string) => setValueForEnableNextButton(() => setMHistoryNotes(text))
            }
            onChangeGeneralNotes={
              (text: string) => setValueForEnableNextButton(() => setGeneralNotes(text))
            }
          />
        )}
    </>
  );
}
