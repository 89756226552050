import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { createSearchParams, useNavigate } from 'react-router-dom';
import { AiFillLock } from 'react-icons/ai';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { RootState } from '../../../store';
import {
  Container, Header, Content, EventNameContainer,
} from './styles';
import FreeUserModal from '../../FreeUserModal';
import theme from '../../../theme';
import CarouselCard from '../../CarouselCard';
import freeUserRedirectLinks from '../../../utils/freeUserRedirectLinks';

export default function EventCard({ event }: { event: any }) {
  const user = useSelector((state: RootState) => state.auth.user);
  const { isFree } = user;

  const [freeModal, setFreeModal] = useState(false);

  const toggleModal = () => {
    setFreeModal((isOpen) => !isOpen);
  };

  const navigate = useNavigate();

  const handlePress = () => {
    if (isFree) {
      setFreeModal(true);
    } else {
      navigate({
        pathname: '/club/live-classes/details',
        search: `?${createSearchParams({
          id: event.id,
        })}`,
      });
    }
  };

  const day = format(new Date(event?.date), 'EEEE', { locale: ptBR });
  const dayFormated = day?.split('-')[0];
  const hours = format(new Date(event?.date), 'HH');
  const min = format(new Date(event?.date), 'mm');

  return (
    <CarouselCard>
      <Container onClick={() => handlePress()} className="event-card" data-testid="event-container">
        <EventNameContainer>
          {isFree && (
            <>
              <AiFillLock size={20} color={theme.colors.title} />
            </>
          )}
          <Header>{event?.title || ''}</Header>
        </EventNameContainer>
        <Content>{`${dayFormated || ''}, ${hours || ''}h${min || ''}`}</Content>
      </Container>
      {isFree && (
        <FreeUserModal
          isModalOpen
          isVisible={freeModal}
          text="Os eventos ao vivo estão disponíveis apenas para membros do Clube Corrida Perfeita."
          link={freeUserRedirectLinks.events}
          toggle={toggleModal}
        />
      )}
    </CarouselCard>
  );
}
