import { createSlice } from '@reduxjs/toolkit';
import {
  addDays, format, parseISO, startOfISOWeek,
} from 'date-fns';
import toast from 'react-hot-toast';

import { AppThunk } from '.';
import api from '../services/api';

import { ICalendarDayTraining, ITrainingPlan } from '../types/TrainingPlan';

const store = createSlice({
  name: 'trainingPlan',
  initialState: {
    planId: null,
    name: null,
    endDate: null,
    days: [],
    selectedDay: null,
    currentWeekDays: [],
    currentWeek: null,
    totalOfWeeks: null,
    loading: true,
    reloadOnFinishExercise: false,
  } as ITrainingPlan,
  reducers: {
    setPlanId: (state, action) => {
      state.planId = action.payload.data;
    },
    setDays: (state, action) => {
      state.days = action.payload.data;
    },
    setName: (state, action) => {
      state.name = action.payload.data;
    },
    setEndDate: (state, action) => {
      state.endDate = action.payload.data;
    },
    setSelectedDay: (state, action) => {
      state.selectedDay = action.payload.data;
    },
    setCurrentWeekDays: (state, action) => {
      state.currentWeekDays = action.payload.data;
    },
    setCurrentWeek: (state, action) => {
      state.currentWeek = action.payload.data;
    },
    setTotalOfWeeks: (state, action) => {
      state.totalOfWeeks = action.payload.data;
    },
    setLoading: (state, action) => {
      state.loading = action.payload.data;
    },
    setReloadOnFinishExerciseFlag: (state, action) => {
      state.reloadOnFinishExercise = action.payload.flag;
    },
  },
});

export const {
  setPlanId,
  setDays,
  setName,
  setEndDate,
  setSelectedDay,
  setCurrentWeekDays,
  setCurrentWeek,
  setTotalOfWeeks,
  setLoading,
  setReloadOnFinishExerciseFlag,
} = store.actions;

export default store.reducer;

export const putPlanId = (data: number) : AppThunk => (
  (dispatch) => {
    dispatch(setPlanId({ data }));
  }
);

export const putDays = (data: ICalendarDayTraining[]) : AppThunk => (
  (dispatch) => {
    dispatch(setDays({ data }));
  }
);

export const putName = (data: string) : AppThunk => (
  (dispatch) => {
    dispatch(setName({ data }));
  }
);

export const putEndDate = (data: string) : AppThunk => (
  (dispatch) => {
    dispatch(setEndDate({ data }));
  }
);

export const putSelectedDay = (data: ICalendarDayTraining) : AppThunk => (
  (dispatch) => {
    dispatch(setSelectedDay({ data }));
  }
);

export const putCurrentWeekDays = (data: ICalendarDayTraining[]) : AppThunk => (
  (dispatch) => {
    dispatch(setCurrentWeekDays({ data }));
  }
);

export const putCurrentWeek = (data: number) : AppThunk => (
  (dispatch) => {
    dispatch(setCurrentWeek({ data }));
  }
);

export const putTotalOfWeeks = (data: number) : AppThunk => (
  (dispatch) => {
    dispatch(setTotalOfWeeks({ data }));
  }
);

export const putLoading = (data: boolean) : AppThunk => (
  (dispatch) => {
    dispatch(setLoading({ data }));
  }
);

export const loadTrainingPlan = (startDate: string, endDate: string, onlyInit: boolean = false, update: boolean = false, noResetSelectedDayToday: boolean = false) : AppThunk => (
  (dispatch, state) => {
    const { user } = state().auth;
    const calendarDays = state().trainingPlan.days;
    const calendarDaySelected = state().trainingPlan.selectedDay;
    const today = new Date();

    api.get(`/calendar_user_training_plans/${user?.id}?start_date=${startDate}&end_date=${endDate}`)
      .then((response) => {
        if (response.data.days.length) {
          const {
            trainingPlanId: planId,
            trainingPlanName: planName,
            trainingPlanEndDate: planEndDate,
            currentWeek,
            totalOfWeeks,
            days,
          } = response.data;

          if (calendarDays.length === 0 || update) {
            dispatch(putDays(days));
          } else {
            dispatch(putDays([...calendarDays, ...days]));
          }

          if (onlyInit) {
            if (noResetSelectedDayToday) {
              dispatch(putSelectedDay(days.find((day: ICalendarDayTraining) => day.date === calendarDaySelected?.date)));
            } else {
              dispatch(putSelectedDay(days.find((day: ICalendarDayTraining) => day.date === format(today, 'yyyy-MM-dd'))));
            }

            dispatch(putPlanId(planId));

            dispatch(putName(planName));

            dispatch(putEndDate(planEndDate));

            if (currentWeek && totalOfWeeks) {
              dispatch(putCurrentWeek(currentWeek));

              dispatch(putTotalOfWeeks(totalOfWeeks));
            }

            const week: ICalendarDayTraining[] = [];
            const startWeek = format(startOfISOWeek(today), 'yyyy-MM-dd');

            for (let index = 0; index < 7; index++) {
              const dayDate = addDays(parseISO(startWeek), index);
              const formattedDate = format(dayDate, 'yyyy-MM-dd');

              const day = days.find((item: ICalendarDayTraining) => item.date === formattedDate);

              if (day) {
                week.push({
                  id: day.id,
                  date: day.date,
                  dayOfWeek: day.dayOfWeek,
                  status: day.status,
                  trainingsOfDay: day.trainingsOfDay,
                });
              } else {
                week.push({
                  id: index + 1,
                  date: format(dayDate, 'yyyy-MM-dd'),
                  dayOfWeek: dayDate.getDay(),
                  status: null,
                  trainingsOfDay: [],
                });
              }
            }

            dispatch(putCurrentWeekDays(week));
          }
        }

        dispatch(putLoading(false));
      })
      .catch(() => {
        dispatch(putLoading(false));

        toast.error('Ocorreu um erro ao carregar o treino!');
      });
  }
);

export const resetDay = () : AppThunk => (
  (dispatch, getState) => {
    const today = format(new Date(), 'yyyy-MM-dd');
    const { days } = getState().trainingPlan;
    if (days.length > 0) {
      dispatch(putSelectedDay(days.find((day: ICalendarDayTraining) => day.date === today)!));
    }
  }
);

export const reloadTrainingPlanOnFinishExerciseExecution = (flag: boolean) : AppThunk => (
  (dispatch) => {
    dispatch(setReloadOnFinishExerciseFlag({ flag }));
  }
);
