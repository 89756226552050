import React from 'react';
import { useSelector } from 'react-redux';
import { RootState } from '../../store';
import { Itab } from '../../pages/Training';
import { ContainerTabs } from './styles';
import { hasAccess } from '../../services/utils';

type TabsProps = {
  tabs: Itab[] | null;
  selectedTab: number;
  onClick: (index: number) => void;
  orientation?: 'horizontal' | 'vertical';
  className?: string;
};

function Tabs({
  className = 'tabs-component',
  tabs = [],
  selectedTab = 0,
  onClick,
  orientation = 'horizontal',
}: TabsProps) {
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;
  const Panel = tabs && tabs.find((tab) => tab.index === selectedTab);

  return (
    <ContainerTabs>
      <div
        className={
          orientation === 'vertical' ? `${className} vertical` : className
        }
      >
        {hasAccess(user, ['FREE', 'ESSENTIAL', 'PERSONAL'])
          && (
            <div role="tablist" aria-orientation={orientation}>
              {tabs && tabs.map((tab) => (
                <button
                  className={selectedTab === tab.index ? 'active' : ''}
                  onClick={() => onClick(tab.index)}
                  key={tab.index}
                  type="button"
                  role="tab"
                  aria-selected={selectedTab === tab.index}
                  aria-controls={`tabpanel-${tab.index}`}
                  tabIndex={selectedTab === tab.index ? 0 : -1}
                  id={`btn-${tab.index}`}
                >
                  <img src={tab.icon} alt={tab.alt} />
                  {tab.label}
                </button>
              ))}
            </div>
          )}
        <div
          role="tabpanel"
          aria-labelledby={`btn-${selectedTab}`}
          id={`tabpanel-${selectedTab}`}
        >
          {Panel && <Panel.Component />}
        </div>
      </div>
    </ContainerTabs>
  );
}
export default Tabs;
