import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../../store';
import {
  Container, BadgeTitle, Badge, BadgeInnerWeekValue, BadgeOuterContainer, BadgeInnerContainer,
} from './styles';

import Lvl1 from '../../assets/progress/badge-icons/lvl1.svg';
import Lvl2 from '../../assets/progress/badge-icons/lvl2.svg';
import Lvl3 from '../../assets/progress/badge-icons/lvl3.svg';
import Lvl4 from '../../assets/progress/badge-icons/lvl4.svg';
import Lvl5 from '../../assets/progress/badge-icons/lvl5.svg';
import Lvl6 from '../../assets/progress/badge-icons/lvl6.svg';
import BadgeCompleted from '../../assets/progress/badge-icons/badge-completed.svg';
import BadgeBlocked from '../../assets/progress/badge-icons/badge-blocked.svg';
import BadgeModal from '../BadgeModal';
import { User, refreshUser } from '../../store/Auth.store';
import BadgeLevelsModal from '../BadgeLevelsModal';

export interface IBadge {
  id: number;
  title: string;
  description: string;
  icon?: string;
  elevatedLevel?: boolean;
  secondaryTitle?: string;
}

export function Badges(genre: User['genre']): IBadge[] {
  return ([
    {
      id: 1,
      title: `${(genre === 'F' || genre === 'Feminino') ? 'Corredora' : 'Corredor'} em treinamento`,
      description: 'Continue firme e confiante, cada passo leva você rumo a grandes conquistas!',
      icon: Lvl1,
    },
    {
      id: 2,
      title: `${(genre === 'F' || genre === 'Feminino') ? 'Corredora dedicada' : 'Corredor dedicado'}`,
      description: 'Mantenha sua dedicação, pois ela é a chave para o sucesso!',
      icon: Lvl2,
    },
    {
      id: 3,
      title: `${(genre === 'F' || genre === 'Feminino') ? 'Corredora' : 'Corredor'} consistente`,
      description: 'Continue assim, pois a consistência é o segredo para a melhoria constante!',
      icon: Lvl3,
    },
    {
      id: 4,
      title: `${(genre === 'F' || genre === 'Feminino') ? 'Corredora determinada' : 'Corredor determinado'}`,
      description: 'Você está treinando com determinação e foco. Siga firme!',
      icon: Lvl4,
    },
    {
      id: 5,
      title: `${(genre === 'F' || genre === 'Feminino') ? 'Corredora vitoriosa' : 'Corredor vitorioso'}`,
      description: 'Sua regularidade até aqui já é uma vitória. Celebre e não pare!',
      icon: Lvl5,
    },
    {
      id: 6,
      title: `${(genre === 'F' || genre === 'Feminino') ? 'Corredora' : 'Corredor'} imbatível`,
      description: 'Parabéns! Agora você alcançou o status de imbatível. Apenas não pare!',
      icon: Lvl6,
    },
    {
      id: 7,
      title: 'Semanas de treinos regulares',
      description: 'Parabéns! Agora você alcançou o status de imbatível. Apenas não pare!',
      elevatedLevel: true,
      secondaryTitle: `${(genre === 'F' || genre === 'Feminino') ? 'Corredora' : 'Corredor'}\nimbatível`,
    },
  ]
  );
}

export default function RegularityLevelProgress() {
  const dispatch = useDispatch();
  const { id: userId, genre: userGenre } = useSelector((state: RootState) => state.auth.user);
  const badgesList = Badges(userGenre);
  const weeks_regularity = useSelector((state: RootState) => state.auth.user.weeks_regularity);
  const [badgeModalVisible, setBadgeModalVisible] = useState(false);
  const [badgeToShow, setBadgeToShow] = useState<IBadge>(badgesList[0]);
  const [badgeLevelsModalVisible, setBadgeLevelsModalVisible] = React.useState(false);

  function handleBadgePress(badgeToShow: IBadge) {
    if (weeks_regularity === badgeToShow.id) {
      setBadgeToShow(badgeToShow);
      setBadgeModalVisible(true);
    } else if (weeks_regularity > 6 && badgeToShow.id === 6) {
      setBadgeToShow(badgesList[6]);
      setBadgeModalVisible(true);
    }
  }

  async function checkForNewAchievements() {
    const previousRegularity = await localStorage.getItem('previousRegularity');

    if (previousRegularity) {
      if (weeks_regularity > Number(previousRegularity)) {
        const newBadge = weeks_regularity < 7 ? badgesList.find((badge) => weeks_regularity === badge.id) : badgesList[6];

        if (newBadge) {
          setBadgeToShow(newBadge);
          setBadgeModalVisible(true);
        }
      }
    } else {
      setBadgeLevelsModalVisible(true);
    }

    if (weeks_regularity) {
      await localStorage.setItem('previousRegularity', weeks_regularity.toString());
    }
  }

  useEffect(() => {
    checkForNewAchievements();
  }, [weeks_regularity]);

  useEffect(() => {
    dispatch(refreshUser(userId) as any);
  }, []);

  return (
    <>
      <BadgeModal
        showModal={badgeModalVisible}
        closeModalCallback={() => setBadgeModalVisible(false)}
        badgeToShow={badgeToShow}
      />

      <BadgeLevelsModal
        showModal={badgeLevelsModalVisible}
        closeModalCallback={() => setBadgeLevelsModalVisible(false)}
      />

      <Container data-testid="regularity-level-progress-container">
        {badgesList.slice(0, badgesList.length).map((badge) => badge.id < 7 && (
          <BadgeOuterContainer dimmed={weeks_regularity < badge.id - 1} key={badge.id}>
            <BadgeInnerContainer key={badge.id} onClick={() => handleBadgePress(badge)} active={weeks_regularity === badge.id || (badge.id === 6 && weeks_regularity > 6)}>
              {
                (((weeks_regularity === badge.id) || (badge.id === 6 && weeks_regularity >= 6)) && !!badge.icon && (
                  <Badge src={badge.icon} alt="Última badge conquistada" bigger />
                ))
                || (weeks_regularity > badge.id && (
                  <Badge src={BadgeCompleted} alt="Badge completa" />
                ))
                || (weeks_regularity < badge.id && (
                  <Badge src={BadgeBlocked} alt="Badge bloqueada" />
                ))
              }
              {weeks_regularity < badge.id && (<BadgeInnerWeekValue>{badge.id}</BadgeInnerWeekValue>)}
            </BadgeInnerContainer>

            <BadgeTitle active={(weeks_regularity === badge.id || (badge.id === 6 && weeks_regularity > 6))}>
              {
                ((weeks_regularity === badge.id || (badge.id === 6 && weeks_regularity > 6)) && badge.title)
                || (weeks_regularity < badge.id && (
                  `semana${badge.id > 1 ? 's' : ''}`
                ))
              }
            </BadgeTitle>
          </BadgeOuterContainer>
        ))}
      </Container>
    </>

  );
}
