import React, { memo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { format } from 'date-fns';
import { BsPerson } from 'react-icons/bs';
import { ptBR } from 'date-fns/locale';
import { RootState } from '../../store';
import {
  ActivityName, Avatar, AvatarContainer, Card, DateText, DistanceContainer,
  MetricContainer, MetricName, MetricText, NameContainer, PaceContainer,
  ShoesBadge,
  ShoesBadgeContainer,
  ShoesBadgeImage,
  TimeContainer, UserContainer, UserName,
} from './style';
import { formatDuration, formatDistance, getGenre } from '../../utils';
import { Activity, setCurrentEditActivity } from '../../store/Activities.store';
import runIcon from '../../assets/activity/run-icon.png';
import { appTheme } from '../../theme';

export interface ActivityListItemProps {
  activity: Activity;
}

export function ActivityListItem({
  activity,
}: ActivityListItemProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;

  const date = format(new Date(activity.start_date), "dd MMM yyyy 'às' HH:mm", { locale: ptBR });

  const isRun = ['Run', 'VirtualRun', 'Walk'].some((t) => t === activity?.type);

  return (
    <Card
      data-testid="activity-list-item"
      onClick={() => {
        dispatch(setCurrentEditActivity(activity) as any);
        navigate(
          '/activity-detail',

        );
      }}
    >

      <AvatarContainer>
        {user.s_photo
          ? <Avatar src={user.s_photo} />
          : (
            <BsPerson
              size={40}
              color="white"
              style={{ marginRight: 5 }}
            />
          )}
        <UserContainer>
          <UserName>{user.name}</UserName>
          <DateText>{date}</DateText>
        </UserContainer>
      </AvatarContainer>
      <NameContainer>
        <ActivityName>{activity?.title ? activity.title : ''}</ActivityName>

        {
          isRun
          && (
            <ShoesBadgeContainer>
              <ShoesBadgeImage src={runIcon} />

              {
                !!activity?.barefoot
            && !activity?.shoes
            && (
              <ShoesBadge>
                Nenhum, corri
                {' '}
                {getGenre(user?.genre!) === 'female' ? 'descalça' : 'descalço'}
              </ShoesBadge>
            )
              }

              {
                !activity?.barefoot
            && !activity?.shoes
            && (
              <ShoesBadge style={{ color: appTheme.zone4 }}>
                Vincular tênis
              </ShoesBadge>
            )
              }

              {
                !!activity?.shoes
            && (
              <ShoesBadge>
                {activity.shoes.shoesBrand?.name}
                {' '}
                -
                {' '}
                {activity.shoes.name}
              </ShoesBadge>
            )
              }
            </ShoesBadgeContainer>
          )
        }
      </NameContainer>
      <MetricContainer>
        {activity.distance ? (
          <DistanceContainer>
            <MetricName> Distância </MetricName>
            <MetricText>
              {formatDistance(activity.distance)}
            </MetricText>
          </DistanceContainer>
        ) : <></>}
        {activity.pace ? (
          <PaceContainer>
            <MetricName> Ritmo </MetricName>
            <MetricText>
              {`${formatDuration(activity.pace, 'pace')} /km`}
            </MetricText>
          </PaceContainer>
        ) : <></>}
        {activity.duration ? (
          <TimeContainer>
            <MetricName>Tempo</MetricName>
            <MetricText>
              {`${formatDuration(activity.duration as number, '')}`}
            </MetricText>
          </TimeContainer>
        ) : <></>}
      </MetricContainer>
    </Card>
  );
}

export default memo(ActivityListItem);
