import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronLeft } from 'react-icons/bs';
import Select from 'react-select';
import { useSelector } from 'react-redux';
import theme from '../../theme';
import {
  Container,
  BackArrow,
  Button,
  Title,
  Text,
  NoticeChannel,
  GroupButton,
  ButtonTitle,
  GroupContainer,
  HeaderGroups,
  FormatGroupLabel,
} from './style';
import { RootState } from '../../store';

const LINKS = {
  CANAL_DE_AVISOS: 'https://chat.whatsapp.com/L12PPayljRl9IbmACMa0pn',
  GERAL: 'https://t.me/joinchat/CfueNVBmmE-xxnSgCylY9A',
  INICIANTES: 'https://t.me/joinchat/6NPVW3aDB2k3ZTRh',
  TENIS_E_VESTUARIO: 'https://t.me/joinchat/IZugLUzh0RxGul1aLSSpBQ',
  RELOGIO_E_ACESSORIOS: 'https://t.me/joinchat/IZugLUjAxweIAQbMDSMmvA',
  CENTRO_OESTE: 'https://t.me/joinchat/MCmTFRNhmzJjcDTtEsBIAQ',
  NORDESTE: 'https://t.me/joinchat/MCmTFRYaAxccHBfCJjMvaQ',
  NORTE: 'https://t.me/joinchat/MCmTFRbKe3n6PgSa2d8OBw',
  SUDESTE: 'https://t.me/joinchat/MCmTFQ8IpvfbNwAlybj4Iw',
  SUL: 'https://t.me/joinchat/MCmTFRWchvzGsKhfRKeY-w',
  BRASILIA: 'https://chat.whatsapp.com/FsNmWRIPSOWHzaXZlxOlgw',
  SP: 'https://chat.whatsapp.com/DGiQb4cQDKsFe4MxDjR3or',
  SP_CAPITAL: 'https://chat.whatsapp.com/Bcoj44jvVR8KCHxDT1aebg',
  PORTUGAL: 'https://t.me/joinchat/IZugLUlBZ_I4FpRLQE_nPA',
  EXTERIOR: 'https://chat.whatsapp.com/KxGYJdUGIfG6rYUznar39P',
  SALVADOR: 'https://chat.whatsapp.com/K5AjxYkGk8AKcVi8TXCDZO',
};

function formatGroupLabel(data: any) {
  return (
    <FormatGroupLabel>
      <span>{data.label}</span>
    </FormatGroupLabel>
  );
}

const customStyles = {
  control: (provided: any) => ({
    ...provided,
    width: '250px',
  }),
  menu: (provided: any) => ({
    ...provided,
    backgroundColor: '#bdbdbd',
    overflow: 'hidden',
  }),
  option: (provided: any) => ({
    ...provided,
    textAlign: 'start',
    color: `${theme.colors.primary}`,
    backgroundColor: 'white',
  }),
};

export default function TelegramGroups({ bigger }: { bigger: boolean }) {
  const auth = useSelector((state: RootState) => state.auth);
  const { user } = auth;

  let showWhatsAppFemale = false;

  if (user.genre && (user.genre.toUpperCase() === 'FEM' || user.genre.toUpperCase() === 'FEMININO' || user.genre.toUpperCase() === 'F')) {
    showWhatsAppFemale = true;
  }

  const navigate = useNavigate();
  const goToBack = () => {
    navigate('/club');
  };
  const [selectedGroup, setSelectedGroup] = useState('');

  const handleGroupSelect = (selectedOption: any) => {
    setSelectedGroup(selectedOption.value);
  };

  const handleGroupButtonClick = (link: any) => {
    window.location.href = link;
  };

  const links: any = [
    {
      name: 'Estado - São Paulo',
      type: 'Estado',
      buttons: [
        {
          name: 'Canal de avisos',
          link: 'https://chat.whatsapp.com/Jg6oeuBNjfI7V2zSANoA5M',
        },
        {
          name: 'São Paulo',
          link: LINKS.SP,
        },
      ],
    },
    {
      name: 'São Paulo - Capital e Região',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'São Paulo - Capital e Região',
          link: LINKS.SP_CAPITAL,
        },
      ],
    },
    {
      name: 'São Paulo - Baixada Santista',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Baixada Santista',
          link: 'https://chat.whatsapp.com/DY2yGqjHZGi2AqbAbOxlUX',
        },
      ],
    },
    {
      name: 'São Paulo - SJC e Região',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'São José dos Campos',
          link: 'https://chat.whatsapp.com/In2uQrOziFIAi1NkD9SeM9',
        },
      ],
    },
    {
      name: 'São Paulo - Campinas e Região',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Campinas',
          link: 'https://chat.whatsapp.com/EBO5FaYWThID9DJfGR9cZ6',
        },
      ],
    },

    {
      name: 'Estado - Rio de Janeiro',
      type: 'Estado',
      buttons: [
        {
          name: 'Estado - Rio de Janeiro',
          link: 'https://chat.whatsapp.com/GZW9mVJgBO1G8A2aBkAk7q',
        },
      ],
    },
    {
      name: 'Rio de Janeiro - Capital e Região',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Rio de Janeiro (capital e região)',
          link: 'https://chat.whatsapp.com/KIPK9CmGvZU4V5WePP3UzA',
        },
      ],
    },
    {
      name: 'Estado - Espiríto Santo',
      type: 'Estado',
      buttons: [
        {
          name: 'Espírito Santo',
          link: 'https://chat.whatsapp.com/DMSh48MPftFA4dck9ZvHcF',
        },
      ],
    },
    {
      name: 'Estado - Minas Gerais',
      type: 'Estado',
      buttons: [
        {
          name: 'Minas Gerais',
          link: 'https://chat.whatsapp.com/I677r3Ee9Jd2dUmqgfsbtO',
        },
      ],
    }, {
      name: 'Belo Horizonte',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Belo Horizonte',
          link: 'https://chat.whatsapp.com/BwB160FYlYBGBkcULWGxuN',
        },
      ],
    },
    {
      name: 'Estado - Paraná',
      type: 'Estado',
      buttons: [
        {
          name: 'Paraná',
          link: 'https://chat.whatsapp.com/FmxERBOimtvHDqarMQOkdt',
        },
      ],
    },
    {
      name: 'Paraná - Curitiba',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Curitiba',
          link: 'https://chat.whatsapp.com/DagyFui8cDR3MRv37ZhNeI',
        },
      ],
    },
    {
      name: 'Estado - Rio Grande do Sul',
      type: 'Estado',
      buttons: [
        {
          name: 'Rio Grande do Sul',
          link: 'https://chat.whatsapp.com/JbUJYzk3din4PbiPUSaAHu',
        },
      ],
    },
    {
      name: 'Rio Grande do Sul - Porto Alegre',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Porto Alegre',
          link: 'https://chat.whatsapp.com/KDD8YDdjN2oI2NYVzZM2CP',
        },
      ],
    },
    {
      name: 'Estado - Santa Catarina',
      type: 'Estado',
      buttons: [
        {
          name: 'Santa Catarina',
          link: 'https://chat.whatsapp.com/DlD1EqPeJtgFGD4X3WtlWD',
        },
      ],
    },
    {
      name: 'Santa Catarina - Floripa e Região',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Floripa',
          link: 'https://chat.whatsapp.com/JvVl8lSrgqS7I0cHfQ9Ijc',
        },
      ],
    },
    {
      name: 'Estado - Brasília DF',
      type: 'Estado',
      buttons: [
        {
          name: 'Canal de avisos',
          link: 'https://chat.whatsapp.com/EOBmPLid4XrGuLYhsgEYw7',
        },
        {
          name: 'Brasília',
          link: LINKS.BRASILIA,
        },
      ],
    },
    {
      name: 'Estado - Goiás',
      type: 'Estado',
      buttons: [
        {
          name: 'Goiás',
          link: 'https://chat.whatsapp.com/C14pmxAmieZ4gD9vU2tiWa',
        },
      ],
    },
    {
      name: 'Goiás - Goiânia',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Goiânia e Região',
          link: 'https://chat.whatsapp.com/K7VlqZmIYorH1HIJLbeIzK',
        },
      ],
    },
    {
      name: 'Estado - Mato Grosso',
      type: 'Estado',
      buttons: [
        {
          name: 'Mato Grosso',
          link: 'https://chat.whatsapp.com/Iomwi24D96pEcZ3fPAG5L9',
        },
      ],
    },
    {
      name: 'Estado - Mato Grosso do Sul',
      type: 'Estado',
      buttons: [
        {
          name: 'Mato Grosso do Sul',
          link: 'https://chat.whatsapp.com/KmeQKt9f2mU0W0hqUTSMME',
        },
      ],
    },
    {
      name: 'Estado - Bahia',
      type: 'Estado',
      buttons: [
        {
          name: 'Bahia',
          link: 'https://chat.whatsapp.com/KoqFN2eUSoSG6YplWp6A0w',
        },
      ],

    },
    {
      name: 'Bahia - Salvador',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Salvador',
          link: LINKS.SALVADOR,
        },
      ],
    },
    {
      name: 'Estado - Ceará',
      type: 'Estado',
      buttons: [
        {
          name: 'Ceará',
          link: 'https://chat.whatsapp.com/JrN5d8eAnzw5iTES7F776Z',
        },
      ],
    },
    {
      name: 'Ceará - Fortaleza',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Fortaleza',
          link: 'https://chat.whatsapp.com/I0b6j0UGfOX8p0SUushotJ',
        },
      ],
    },
    {
      name: 'Estado - Pernambuco',
      type: 'Estado',
      buttons: [
        {
          name: 'Pernambuco',
          link: 'https://chat.whatsapp.com/IxojT2v8gwpEllyYQini1D',
        },
      ],
    },
    {
      name: 'Pernambuco - Recife',
      type: 'CidadeRegiao',
      buttons: [
        {
          name: 'Recife',
          link: 'https://chat.whatsapp.com/L1KCkOG5TjRHm5BrqP1JXF',
        },
      ],
    },
    {
      name: 'Estado - Paraíba',
      type: 'Estado',
      buttons: [
        {
          name: 'Paraíba',
          link: 'https://chat.whatsapp.com/CTEVui5IrbuIE7HaV6ss7P',
        },
      ],
    },
    {
      name: 'Estado - Rio Grande do Norte',
      type: 'Estado',
      buttons: [
        {
          name: 'Rio Grande do Norte',
          link: 'https://chat.whatsapp.com/EQpWTKWiz80KBqmnE7DkVb',
        },
      ],
    },
    {
      name: 'Estado - Piauí',
      type: 'Estado',
      buttons: [
        {
          name: 'Piauí',
          link: 'https://chat.whatsapp.com/LBD7PxedEsZGzJ50WEcssk',
        },
      ],
    },
    {
      name: 'Estado - Sergipe',
      type: 'Estado',
      buttons: [
        {
          name: 'Sergipe',
          link: 'https://chat.whatsapp.com/FHoYhvIRasOKgh3uF8LoOL',
        },
      ],
    },
    {
      name: 'Estado - Alagoas',
      type: 'Estado',
      buttons: [
        {
          name: 'Alagoas',
          link: 'https://chat.whatsapp.com/Hx3rIIIWzGwLUbEpqHNtbp',
        },
      ],
    },
    {
      name: 'Estado - Maranhão',
      type: 'Estado',
      buttons: [
        {
          name: 'Maranhão',
          link: 'https://chat.whatsapp.com/FL8YWYsBz3Q8auYSRyDTwd',
        },
      ],
    },
    {
      name: 'Estado - Amazonas',
      type: 'Estado',
      buttons: [
        {
          name: 'Amazonas',
          link: 'https://chat.whatsapp.com/JjzX2gxCNCC83f6t4byh29',
        },
      ],
    },
    {
      name: 'Estado - Tocantins',
      type: 'Estado',
      buttons: [
        {
          name: 'Tocantins',
          link: 'https://chat.whatsapp.com/Eh0pXXIqfsSH12nsdg8zu6',
        },
      ],
    },
    {
      name: 'Estado - Rondônia',
      type: 'Estado',
      buttons: [
        {
          name: 'Rondônia',
          link: 'https://chat.whatsapp.com/D3xzmpOcHhi3RaXGemlgjz',
        },
      ],
    },
    {
      name: 'Estado - Roraima',
      type: 'Estado',
      buttons: [
        {
          name: 'Roraima',
          link: 'https://chat.whatsapp.com/LZrYFJs2rkb7VhnEWKQs7D',
        },
      ],
    },
    {
      name: 'Estado - Acre',
      type: 'Estado',
      buttons: [
        {
          name: 'Acre',
          link: 'https://chat.whatsapp.com/EjS5B27cbc77gVrjgc4W8a',
        },
      ],
    },
    {
      name: 'Estado - Pará',
      type: 'Estado',
      buttons: [
        {
          name: 'Pará',
          link: 'https://chat.whatsapp.com/Ku6Cvy9DnvsErH0qCMh0so',
        },
      ],
    },
    {
      name: 'Amapá',
      type: 'Estado',
      buttons: [
        {
          name: 'Amapá',
          link: 'https://chat.whatsapp.com/DiND4BvK2nm9pz52tJ5qpy',
        },
      ],
    },
  ].sort((n1, n2) => {
    if (n1.name > n2.name) {
      return 1;
    }

    if (n1.name < n2.name) {
      return -1;
    }

    return 0;
  });

  const groupData: { name: string, type: 'Estado' | 'CidadeRegiao', buttons: { name: string, link: string }[] }[] = links;

  const marathonData = [
    {
      name: 'Maratona de Berlim',
      buttons: [
        {
          name: 'Maratona Int’l de Berlim',
          link: 'https://chat.whatsapp.com/DKpQ2xNOKi8ClOpCwHuO0A',
        },
      ],
    },
    {
      name: 'Maratona de São Paulo',
      buttons: [
        {
          name: 'Maratona Int’l de São Paulo',
          link: 'https://chat.whatsapp.com/G7LhPVw7hQv1qBkcyrjqLn',
        },
      ],
    },
    {
      name: 'SP City',
      buttons: [
        {
          name: 'SP City',
          link: 'https://chat.whatsapp.com/E1Tcr0kIToJ13BvSBb6Mz5',
        },
      ],
    },
    {
      name: 'Maratona do Rio',
      buttons: [
        {
          name: 'Maratona do Rio',
          link: 'https://chat.whatsapp.com/Jo716TdVWp1I6CtjIBNANt',
        },
      ],
    },
    {
      name: 'Rio City',
      buttons: [
        {
          name: 'Rio City Half Marathon',
          link: 'https://chat.whatsapp.com/G3nOyfFGPpLL1OsSUQENeL',
        },
      ],
    },
    {
      name: 'Maratona de Porto Alegre',
      buttons: [
        {
          name: 'Maratona de Porto Alegre',
          link: 'https://chat.whatsapp.com/DGMR1bNHDfQ8zg5jXWS3j1',
        },
      ],
    },
    {
      name: 'Tribuna de Santos',
      buttons: [
        {
          name: 'Tribuna de Santos',
          link: 'https://chat.whatsapp.com/LNBciJ8iRMBLYsi6i5ASRw',
        },
      ],
    },
    {
      name: 'Maratona de João Pessoa',
      buttons: [
        {
          name: 'Maratona de João Pessoa',
          link: 'https://chat.whatsapp.com/J1SNiVJKPuPEsGweGGn2cA',
        },
      ],
    },

    {
      name: 'Meia Int\'l do Rio de Janeiro',
      buttons: [
        {
          name: 'Meia Int\'l do Rio de Janeiro',
          link: 'https://chat.whatsapp.com/CkSmFexhsOb8B3vs90WHwM',
        },
      ],
    },

    {
      name: 'Maratona Int\'l de Floripa',
      buttons: [
        {
          name: 'Maratona Int\'l de Floripa',
          link: 'https://chat.whatsapp.com/FksMJDfEGMu9yVxOI3pO3E',
        },
      ],
    },

    {
      name: 'Meia do Sol (Natal/RN)',
      buttons: [
        {
          name: 'Meia do Sol (Natal/RN)',
          link: 'https://chat.whatsapp.com/DR7rclqvTmFIun0YaE0qf0',
        },
      ],
    },

    {
      name: '10 Milhas Garoto (Vitória/ES)',
      buttons: [
        {
          name: '10 Milhas Garoto (Vitória/ES)',
          link: 'https://chat.whatsapp.com/He4Hh71t09Y3BS8puO0uAo',
        },
      ],
    },

    {
      name: 'Meia de Sampa',
      buttons: [
        {
          name: 'Meia de Sampa',
          link: 'https://chat.whatsapp.com/HmWO24prIOB7LLH1Zoepue',
        },
      ],
    },

    {
      name: 'BSB City',
      buttons: [
        {
          name: 'BSB City',
          link: 'https://chat.whatsapp.com/JovI5VOHAAxL4obfvFlaOz',
        },
      ],
    },

    {
      name: 'Volta da Pampulha',
      buttons: [
        {
          name: 'Volta da Pampulha',
          link: 'https://chat.whatsapp.com/EQgXnDapfFGFvYf6MdPaDB',
        },
      ],
    },

    {
      name: 'São Silvestre',
      buttons: [
        {
          name: 'São Silvestre',
          link: 'https://chat.whatsapp.com/BqMyyXXp3mWKOVLNEmmgUL',
        },
      ],
    },
  ];

  groupData.map((group) => ({
    value: group.name,
    label: group.name,
  }));

  const selectedGroupData = groupData.filter((group) => selectedGroup === '' ? false : group.name.includes(selectedGroup)); // eslint-disable-line

  const dropdownOptions = [
    {
      label: 'Selecione',
      options: groupData.map(({ name }) => ({ value: name, label: name })),
    },
  ];

  const selectedMarathonData = marathonData.find((group) => group.name === selectedGroup);

  const dropdownMarathonOptions = marathonData.map((group) => ({
    value: group.name,
    label: group.name,
  }));

  const distanceData = [
    {
      name: '5 e 10km',
      buttons: [
        {
          name: '5 e 10km',
          link: 'https://chat.whatsapp.com/LEi8BjFC07q2NwaGGJEA3D',
        },
      ],
    },
    {
      name: '21km',
      buttons: [
        {
          name: '21km',
          link: 'https://chat.whatsapp.com/BsMMUeASzo4FsjWuKir3SX',
        },
      ],
    },

    {
      name: '42km',
      buttons: [
        {
          name: '42km',
          link: 'https://chat.whatsapp.com/CTjorr2aLmRAlBdqEqjTnD',
        },
      ],
    },

    {
      name: 'Trail',
      buttons: [
        {
          name: 'Trail',
          link: 'https://chat.whatsapp.com/FeJygwk7k38F4hZHf3VwaH',
        },
      ],
    },
  ];

  if (showWhatsAppFemale) {
    distanceData.push({
      name: 'Clube CP Mulheres ',
      buttons: [
        {
          name: 'Clube CP Mulheres ',
          link: 'https://chat.whatsapp.com/BmeBJs3mb1F9RNcPmKRA5F',
        },
      ],
    });
  }
  const selectedDistanceData = distanceData.find((group) => group.name === selectedGroup);

  const dropdownDistanceOptions = distanceData.map((group) => ({
    value: group.name,
    label: group.name,
  }));

  return (
    <Container>
      <BackArrow>
        <Button type="button" onClick={goToBack}>
          <BsChevronLeft color="#FFFFFF" size={bigger ? 24 : 22} />
        </Button>
        <Title onClick={goToBack}>grupos no Whatsapp</Title>
      </BackArrow>
      <Text>
        Nossas comunidades de clientes estão reunidos em grupos regionais no WhatsApp. Esses grupos se destinam exclusivamente à interação saudável, amistosa e agregador entre os membros do Clube Corrida Perfeita.
      </Text>
      <Text>
        Faça parte do grupo geral de avisos da comunidade e do seu grupo regional. Apresente-se ao entrar.
      </Text>

      <NoticeChannel>
        <h5>Canal de Avisos</h5>
        <p>
          No nosso Canal de Avisos, onde somente a Equipe CP encaminha mensagens.
          Caso você não se interesse por nenhum grupo, recomendamos que ingresse ao menos neste
          Canal de Avisos, pois é o local em que postaremos comunicados de interesse geral de todos os alunos.
        </p>

        <GroupButton
          type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = LINKS.CANAL_DE_AVISOS;
          }}
        >
          <ButtonTitle>entrar</ButtonTitle>
        </GroupButton>
      </NoticeChannel>

      <GroupContainer>
        <HeaderGroups>
          <h5>Grupos Locais</h5>
        </HeaderGroups>
        <Text>
          Digite seu Estado, Cidade ou Região
        </Text>
        <br />
        <div>
          <Select
            options={dropdownOptions}
            onChange={handleGroupSelect}
            placeholder="Digite para buscar..."
            isSearchable
            isClearable
            noOptionsMessage={({ inputValue }) => !inputValue ? '' : 'Não há nenhum grupo'} // eslint-disable-line
            styles={customStyles}
            formatGroupLabel={formatGroupLabel}
          />
          <br />
          {selectedGroupData && (
            <div>
              {selectedGroupData.map((select) => (
                select.buttons.map((button, index) => (
                  <GroupButton
                    key={index}
                    type="button"
                    onClick={(e) => {
                      e.preventDefault();
                      handleGroupButtonClick(button.link);
                    }}
                  >
                    <ButtonTitle>{button.name}</ButtonTitle>
                  </GroupButton>
                ))))}
            </div>
          )}
        </div>
      </GroupContainer>

      <GroupContainer>
        <HeaderGroups>
          <h5>Exterior</h5>
        </HeaderGroups>
        <br />

        <GroupButton
          type="button"
          onClick={(e) => {
            e.preventDefault();
            window.location.href = LINKS.EXTERIOR;
          }}
        >
          <ButtonTitle>Residentes no Exterior</ButtonTitle>
        </GroupButton>
      </GroupContainer>

      <GroupContainer>
        <NoticeChannel>
          <HeaderGroups>
            <h5>Provas e Eventos de Corrida</h5>
          </HeaderGroups>
          <p>
            Aqui temos os grupos para reunir nossos membros e falar da nossa presença e apoio nas provas de maior participação nacional. Para provas locais, as informações serão tratadas nos grupos da respectiva região.
          </p>

        </NoticeChannel>
        <Text>
          Digite o nome da prova
        </Text>
        <br />
        <div>
          <Select
            options={dropdownMarathonOptions}
            onChange={handleGroupSelect}
            placeholder="Digite para buscar..."
            isSearchable
            isClearable
            noOptionsMessage={({ inputValue }) => !inputValue ? '' : 'Não há nenhum grupo'} // eslint-disable-line
            styles={
              {
                control: (provided: any) => ({
                  ...provided,
                  width: '250px',
                }),
                menu: (provided: any) => ({
                  ...provided,
                  backgroundColor: 'white',
                  overflow: 'hidden',
                }),
                option: (provided: any) => ({
                  ...provided,
                  textAlign: 'start',
                  color: `${theme.colors.primary}`,
                  backgroundColor: 'white',
                }),
              }
            }

          />
          <br />
          {selectedMarathonData && (
            <div>
              {selectedMarathonData.buttons.map((button, index) => (
                <GroupButton
                  key={index}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleGroupButtonClick(button.link);
                  }}
                >
                  <ButtonTitle>{button.name}</ButtonTitle>
                </GroupButton>
              ))}
            </div>
          )}
        </div>

      </GroupContainer>

      <GroupContainer>
        <NoticeChannel>
          <HeaderGroups>
            <h5>Temáticos</h5>
          </HeaderGroups>
          <p>
            Aqui temos grupos para interação geral dos atletas segundo seus objetivos de corrida e outros assuntos do universo dos corredores.
          </p>

        </NoticeChannel>
        <Text>
          Digite o tema
        </Text>
        <br />
        <div>
          <Select
            options={dropdownDistanceOptions}
            onChange={handleGroupSelect}
            placeholder="Digite para buscar..."
            isSearchable
            isClearable
            noOptionsMessage={({ inputValue }) => !inputValue ? '' : 'Não há nenhum grupo'} // eslint-disable-line
            styles={
              {
                control: (provided: any) => ({
                  ...provided,
                  width: '250px',
                }),
                menu: (provided: any) => ({
                  ...provided,
                  backgroundColor: 'white',
                  overflow: 'hidden',
                }),
                option: (provided: any) => ({
                  ...provided,
                  textAlign: 'start',
                  color: `${theme.colors.primary}`,
                  backgroundColor: 'white',
                }),
              }
            }

          />
          <br />
          {selectedDistanceData && (
            <div>
              {selectedDistanceData.buttons.map((button, index) => (
                <GroupButton
                  key={index}
                  type="button"
                  onClick={(e) => {
                    e.preventDefault();
                    handleGroupButtonClick(button.link);
                  }}
                >
                  <ButtonTitle>{button.name}</ButtonTitle>
                </GroupButton>
              ))}
            </div>
          )}
        </div>

      </GroupContainer>

    </Container>
  );
}
