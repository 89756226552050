import React, { useEffect } from 'react';
import { IStep, IStepData } from '../..';
import { User } from '../../../../store/Auth.store';
import RequestPlanSetRecord from '../../Components/RequestPlanSetRecord';
import {
  Container, Description, List, Title,
} from './styles';

export interface ISetRecordsScreenProps {
  step: IStep,
  getKmDate: (type: number) => string | null,
  getKmTime: (type: number) => string | null,
  onChangeDate: (index: number, date: string) => void,
  onChangeTime: (index: any, time: string) => void,
  profile: User,
  setButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>,
  distance: number | null,
}

function SetRecordsScreen({
  step, getKmDate, getKmTime, onChangeDate, onChangeTime, profile, setButtonDisabled, distance,
}: ISetRecordsScreenProps) {
  useEffect(() => {
    setButtonDisabled(false);
  }, []);

  return (
    <Container>
      <Title>{step.title}</Title>

      <Description>{step.description}</Description>

      <List>
        {
          step.data.filter((item: any) => item.distance === distance).map((opt: IStepData) => (
            <RequestPlanSetRecord
              key={opt.label}
              opt={opt}
              getKmDate={getKmDate}
              getKmTime={getKmTime}
              onChangeDate={onChangeDate}
              onChangeTime={onChangeTime}
              profile={profile}
            />
          ))
        }
      </List>
    </Container>
  );
}

export default SetRecordsScreen;
