import React from 'react';
import { AiFillLock } from 'react-icons/ai';
import {
  Buttom, ButtonsContainer, Container, Content,
} from './styles';

interface Props {
  modalFreeUser: boolean,
  setModalFreeUser: (modalFreeUser: boolean) => void,
  text: string,
}

function ModalFreeUser({ modalFreeUser, setModalFreeUser, text } : Props) {
  function closeModalFree() {
    setModalFreeUser(false);
  }
  return (
    <>
      {
        modalFreeUser
        && (
          <Container>
            <Content>
              <AiFillLock />
              <p>{text}</p>
              <ButtonsContainer>
                <Buttom
                  primary
                  onClick={(e) => {
                    e.preventDefault();
                    window.open('https://corridaperfeita.com/clube/', '_blank');
                  }}
                >
                  Quero fazer parte
                </Buttom>
                <Buttom
                  onClick={() => closeModalFree()}
                >
                  VOLTAR
                </Buttom>
              </ButtonsContainer>
            </Content>
          </Container>
        )
      }
    </>
  );
}

export default ModalFreeUser;
