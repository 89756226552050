import React, { useState } from 'react';

import {
  Container, Content, Title, Description, Paragraph, BoldParagraph,
} from './style';
import IntegrationConfirmModal from '../IntegrationConfirmModal';
import Switch from '../Switch/index';

interface IntegrationI {
  title: string
  description: string
  enabled: boolean
  paragraphs?: Array<string>
  addIntegration: (dismissIntegration?: () => void) => void
  removeIntegration?: () => void
}

export default function IntegrationContent({
  title,
  description,
  enabled,
  paragraphs,
  addIntegration = () => { },
  removeIntegration = () => { },

}: IntegrationI) {
  const [isIntegrationModalOpen, setIntegrationModalOpen] = useState<boolean>(false);

  const toggleModal = () => {
    setIntegrationModalOpen((isOpen) => !isOpen);
  };

  const onClick = () => {
    toggleModal();
  };

  return (
    <Container>
      <Content>
        <Title>{title}</Title>

        <Description>{description}</Description>

      </Content>

      <Switch isEnabled={enabled} toggle={onClick} />

      <IntegrationConfirmModal
        isOpen={isIntegrationModalOpen}
        confirm={() => {
          if (enabled) {
            removeIntegration?.();
          } else {
            addIntegration?.();
          }
          toggleModal();
        }}
        cancel={() => {
          toggleModal();
        }}

      >
        {
          enabled
            ? <Paragraph>Deseja remover esta integração?</Paragraph>
            : (paragraphs?.map((text, index) => (text.includes('Atenção')
              ? (
                <Paragraph key={index}>
                  <BoldParagraph>Atenção:</BoldParagraph>
                  {text.replace('Atenção:', '')}
                </Paragraph>
              )
              : <Paragraph key={index}>{text}</Paragraph>)))
        }
      </IntegrationConfirmModal>
    </Container>
  );
}
