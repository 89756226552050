import React, { useState } from 'react';
import { BsChevronLeft } from 'react-icons/bs';
import { TbCircle1, TbCircle2, TbCircle3 } from 'react-icons/tb';
import { BiChevronRight, BiChevronLeft } from 'react-icons/bi';
import firstImage from '../../assets/Indication/1.png';
import secondImage from '../../assets/Indication/2.png';
import thirdImage from '../../assets/Indication/3.png';
import {
  Content,
  TitleText,
  ContentText,
  Footer,
  ControlButton,
  ControlText,
  StyledImage,
  TitleContent,
  BackArrowContainer,
  Modal,
  ModalContent,
  ModalDialog,
  ModalBackDrop,
  Container,
  ArrowButton,
  ArrowTitle,
  ImageContainer,
} from './styles';
import CouponModal from './CouponComponent';

interface OnboardModalProps {
  visible: boolean;
  onClose: () => void;
}

export default function IndicationOnboard({ visible, onClose } : OnboardModalProps) {
  const [activePage, setActivePage] = useState(0);
  const pages = [
    { title: 'indique amigos e conhecidos', content: 'Ao indicar amigos e conhecidos para fazerem parte conosco do Clube Corrida Perfeita, eles ganham um desconto e você ganha indicações que poderá usar para resgatar ótimos prêmios.', image: firstImage },
    { title: 'acompanhe as indicações', content: 'Após divulgar seu cupom de indicação para seus contatos, se eles fizerem a adesão ao Clube, você pode acompanhar suas indicações obtidas direto em nossa plataforma.', image: secondImage },
    { title: 'resgate prêmios', content: 'Após confirmadas as indicações, também em nossa plataforma, você poderá solicitar o resgate dos prêmios disponíveis.', image: thirdImage },
  ];
  const [couponModalVisible, setCouponModalVisible] = useState(false);
  const closeCouponModal = () => {
    setCouponModalVisible(false);
  };
  const openCouponModal = () => {
    setCouponModalVisible(true);
    setTimeout(() => { closeCouponModal(); onClose(); }, 5000);
  };
  const handlePrevPage = () => {
    setActivePage(activePage - 1);
  };
  const handleNextPage = () => {
    setActivePage(activePage + 1);
  };

  return (
    <>
      {visible && (
        <>
          <Modal>
            <ModalDialog>
              <ModalContent>
                <CouponModal visible={couponModalVisible} />
                <ImageContainer>
                  <StyledImage imgSrc={pages[activePage].image} />
                  <BackArrowContainer>
                    <Container>
                      <ArrowButton type="button" onClick={onClose}>
                        <BsChevronLeft color="#FFFFFF" size={24} />
                      </ArrowButton>
                      <ArrowTitle onClick={onClose}>
                        INDIQUE E GANHE
                      </ArrowTitle>
                    </Container>
                  </BackArrowContainer>
                </ImageContainer>
                <Content>
                  <TitleContent>
                    { activePage === 0 && (
                      <TbCircle1
                        size={30}
                        style={{
                          color:
'#000033',
                        }}
                      />
                    ) }
                    { activePage === 1 && (
                      <TbCircle2
                        size={30}
                        style={{
                          color:
    '#000033',
                        }}
                      />
                    )}
                    { activePage === 2 && (
                      <TbCircle3
                        size={30}
                        style={{
                          color:
    '#000033',
                        }}
                      />
                    )}
                    <TitleText>
                      {pages[activePage].title}

                    </TitleText>
                  </TitleContent>
                  <ContentText>{pages[activePage].content}</ContentText>
                </Content>
                <Footer activePage={activePage}>
                  {activePage > 0 && (
                    <ControlButton onClick={handlePrevPage}>
                      <BiChevronLeft color="#000033" size={15} />
                      <ControlText>Anterior</ControlText>
                    </ControlButton>
                  )}
                  {activePage < pages.length - 1 && (
                    <ControlButton onClick={handleNextPage}>
                      <ControlText>Próximo</ControlText>
                      <BiChevronRight color="#000033" size={15} />
                    </ControlButton>
                  )}
                  {activePage === pages.length - 1 && (
                    <ControlButton onClick={openCouponModal}>
                      <ControlText>Quero indicar</ControlText>
                      <BiChevronRight color="#000033" size={15} />
                    </ControlButton>
                  )}
                </Footer>
              </ModalContent>
            </ModalDialog>
          </Modal>
          <ModalBackDrop />
        </>
      )}
    </>
  );
}
