import * as React from 'react';
import moment from 'moment';
import {
  addDays, parseISO, format, subDays, startOfMonth, endOfMonth,
} from 'date-fns';
import { Eventcalendar, localePtBR } from '@mobiscroll/react';
import '@mobiscroll/react/dist/css/mobiscroll.react.min.css';

import { useDispatch, useSelector } from 'react-redux';
import $ from 'jquery';

import { useNavigate } from 'react-router-dom';
import { RootState } from '../../store';
import { clearEvents, newRequestEventsData, updateMonth } from '../../store/TrainingCalendar.store';

import {
  Container, CalendarContainer, ActivePlanModal, Button,
} from './style';

import CustomEventCard from './components/CustomEventCard';
import CustomHeader from './components/CustomHeader';

import MobileCalendar from './components/MobileCalendar';
import { ITrainingOfDay } from '../../types/TrainingPlan';
import BaseModal from '../BaseModal';
import { loadTrainingPlan } from '../../store/TrainingPlan.store';
import NewObjective from '../NewObjective/NewObjective';
import Loader from '../Loader';
import { objectExists } from '../../utils';
import CurrentPlanRequest from '../CurrentPlanRequest';
import FreeUserCard from '../FreeUserCard';
import freeUserRedirectLinks from '../../utils/freeUserRedirectLinks';
import { newPlanRequest } from '../../store/TrainingPlanRequests.store';
import PersonalUserCard from '../PersonalUserCard';
import LinkedActivityModal from '../LinkedActivityModal';

export interface ITrainingCalendarProps {
  trainingEvents?: ITrainingOfDay[];
  randomSkeletonSizes?: boolean;
}

export default function TrainingCalendar({ trainingEvents, randomSkeletonSizes }: ITrainingCalendarProps) {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [showMobileVersion, setShowMobileVersion] = React.useState(false);

  const trainingCalendarEvents = trainingEvents || useSelector((state: RootState) => state.trainingCalendar.events);
  const loading = useSelector((state: RootState) => state.trainingCalendar.loading);
  const { endDate, name, loading: trainingPlanLoading } = useSelector((state: RootState) => state.trainingPlan);
  const trainingPlanId = useSelector((state: RootState) => state.trainingPlan.planId);
  const trainingPlanRequest = useSelector((state: RootState) => state.trainingPlanRequests.trainingPlanRequest);
  const auth = useSelector((state: RootState) => state.auth);
  const [activityDetailsModalVisible, setActivityDetailsModalVisible] = React.useState(false);
  const [trainingId, setTrainingId] = React.useState<number | null>(null);

  const past = moment(endDate).isAfter(moment());

  const { user } = auth;
  const isPersonal = user?.subscription_type === 'PERSONAL';
  const isFree = user?.isFree;

  const generalLoading = React.useMemo(() => loading && trainingPlanLoading, [loading, trainingPlanLoading]);

  const showNewObjective = React.useMemo(
    () => (!generalLoading
      && !isFree
      && !isPersonal
      && !objectExists(trainingPlanRequest)
      && !trainingPlanId),
    [isPersonal, trainingPlanRequest, trainingPlanId],
  );

  const showCurrentPlanRequest = React.useMemo(
    () => (!generalLoading
      && !isFree
      && !isPersonal
      && objectExists(trainingPlanRequest)),
    [isPersonal, trainingPlanRequest],
  );

  const EventsData = React.useMemo(() => {
    const mobiScrollEventsData = trainingCalendarEvents.map((calendarEvent) => {
      const mobiscrollEvent = {
        date: moment(`${calendarEvent.date}`),
        resource: JSON.stringify(calendarEvent),
      };

      return mobiscrollEvent;
    });

    return mobiScrollEventsData;
  }, [trainingCalendarEvents]);

  function updateCalendarData(date: Date) {
    dispatch(updateMonth(new Date(date)) as any);
  }

  function randomSkeletonSize(): string {
    if (!randomSkeletonSizes) return '';

    const randomModifier = Math.random();

    if (randomModifier > 0.5) return 'skeleton-small';
    if (randomModifier > 0.7) return 'skeleton-big';
    return '';
  }

  function showLoadingStates() {
    $('.mbsc-calendar-labels').each((_, dayElement) => {
      if ($(dayElement).children().length === 0) {
        $(dayElement).append(
          $(` <div class="loading-skeleton ${randomSkeletonSize()}"/>`),
          $(` <div class="loading-skeleton ${randomSkeletonSize()}"/>`),
          $(` <div class="loading-skeleton ${randomSkeletonSize()}"/>`),
        );
      }
    });
  }

  function updateEmptyStates() {
    $('.mbsc-calendar-labels').each((_, dayElement) => {
      if ($(dayElement).children().length === 0) {
        $(dayElement).append(
          $('<div class="empty-state">x</div>'),
        );
      }
    });
  }

  function handleScreenResize() {
    if (window.innerWidth < 1000) {
      setShowMobileVersion(true);
    } else {
      setShowMobileVersion(false);
    }
  }

  const [planModalVisible, setPlanModalVisible] = React.useState(false);

  function handleActivePlanModalButton() {
    $('#active-plan-button').on('click', () => {
      setPlanModalVisible(true);
    });
  }

  const initialLoad = () => {
    const today = format(new Date(), 'yyyy-MM-dd');
    const parsedDate = parseISO(today!);
    const startAt = format(subDays(startOfMonth(parsedDate!), 7), 'yyyy-MM-dd');
    const endAt = format(addDays(endOfMonth(parsedDate!), 7), 'yyyy-MM-dd');

    dispatch(loadTrainingPlan(startAt!, endAt!, true) as any);
    dispatch(clearEvents() as any);
    dispatch(newRequestEventsData(new Date()) as any);

    handleScreenResize();
    window.addEventListener('resize', handleScreenResize);
  };

  React.useEffect(() => {
    initialLoad();
  }, []);

  React.useEffect(() => {
    if (loading === false) {
      $('.loading-skeleton').remove();

      setTimeout(() => {
        updateEmptyStates();
      }, 500);
    } else {
      showLoadingStates();
    }
  }, [loading]);

  React.useEffect(() => {
    if (!generalLoading) handleActivePlanModalButton();
  }, [generalLoading]);

  const handleOpenActivityDetailsModal = (tId: number) => {
    setTrainingId(tId);
    setActivityDetailsModalVisible(true);
  };

  return (
    <>
      {(generalLoading && <Loader color="white" />)

        || (name && endDate
          && (
            <>
              <BaseModal title="" isVisible={planModalVisible} setIsVisible={setPlanModalVisible}>
                <ActivePlanModal>
                  {
                    !past
                      ? <h1>Plano de treino ativo</h1>
                      : <h1>Você não possui um plano de treino</h1>
                  }

                  <p>{name}</p>
                  <p>
                    {
                      !past
                        ? <>Termina em:</>
                        : <>Terminou em:</>
                    }
                    {' '}
                    {format(new Date(endDate), 'dd/MM/yyyy')}
                  </p>
                  <Button onClick={() => {
                    dispatch(newPlanRequest(true) as any);
                    navigate('/request');
                  }}
                  >
                    Solicitar Novo Plano
                  </Button>
                </ActivePlanModal>
              </BaseModal>

              {(showMobileVersion
                && (
                  <MobileCalendar handleOpenActivityDetailsModal={handleOpenActivityDetailsModal} />
                )
              )
                || (
                  <Container>
                    <CalendarContainer>
                      <Eventcalendar
                        locale={localePtBR}
                        view={{
                          calendar:
                          {
                            labels: true,
                            type: 'week',
                          },
                        }}
                        data={EventsData.reverse()}
                        renderHeader={() => <CustomHeader planRequest={past ? <>Plano Ativo</> : <>Você não possui um plano de treino</>} />}
                        renderLabel={(eventData) => <CustomEventCard eventData={eventData} handleOpenActivityDetailsModal={handleOpenActivityDetailsModal} />}
                        onPageChange={(e) => {
                          updateCalendarData(e.firstDay);
                          updateEmptyStates();
                        }}
                        height="700"
                        showControls
                      />
                    </CalendarContainer>
                  </Container>
                )}
            </>
          ))}

      {
        (showNewObjective && <NewObjective />)
      }

      {
        (showCurrentPlanRequest && <CurrentPlanRequest />)
      }

      {
        isPersonal && (
          <PersonalUserCard
            text="Seu calendário de treinos é prescrito por seu treinador no App Training Peaks"
          />
        )
      }

      {
        (isFree && (
          <FreeUserCard
            text="Os planos de treino de corrida estão disponíveis apenas para membros do Clube Corrida Perfeita"
            link={freeUserRedirectLinks.zones}
          />
        ))
      }

      <LinkedActivityModal
        isVisible={activityDetailsModalVisible}
        close={() => setActivityDetailsModalVisible(false)}
        trainingId={trainingId}
      />
    </>
  );
}
